<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="设备管理"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-delete="deleteFun"
      :on-edit="updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :top-operates="topOperates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
    <JuouDangerDialog
      ref="upateDialog"
      title="更新警告"
      opt-name="更新"
      :on-ok="handleUpdateAction"
      :format-dangder-delete-info="updateDangderInfo"
    />
    <timer ref="timerSettingRef" />
    <Export ref="exportRef" />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import { DEVICEMODE } from '@/api';
import timer from './timer.vue';
import Export from '@/components/Export.vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const exportRef = ref<InstanceType<typeof Export>>();

let paramsLast: any;
let tableDateLast: any;


const addInitValue = {

};
const conditionsInitValue = {
};

const timerSettingRef = ref<InstanceType<typeof timer>>();


const init = useBaseAsync(async() => {
  const res = await useApi().deviceApi.listDeviceTypeDevice();
  const options = res.data.data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));


  columns.value = [
    {
      name: 'remark',
      label: '设备备注',
      type: 'string',
      isCondition: true,
      order: 10,
    },
    {
      name: 'idHex',
      label: '设备序列号',
      type: 'string',
      hideEdit: true,
      hideCreate: true,
      isCondition: true,
    },
    {
      name: 'deviceTypeId',
      label: '设备类型',
      type: 'select',
      options,
      rule: [{ required: true }],
      isCondition: true,
      render: ({ row: { deviceTypeId } }: any) => {
        const deviceType = options.find((item: any) => item.value === deviceTypeId);
        if (!deviceType) {
          return '-';
        }
        return deviceType.label;
      },
    },
    {
      name: 'serialNumber',
      label: '原始序列号',
      isCondition: true,
      type: 'string',
      rule: [{ required: true }],
    },
    {
      name: 'status',
      label: '设备状态',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'mod',
      label: '设备模式',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'version',
      label: '设备版本',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
  ];
});

const handleUpdateAction = useBaseAsync(async(data: any) => {
  await useApi()
    .deviceApi.deviceUpgradeDevice(data.id, {
      headers: {
        'custom-error': 'true',
      },
    })
    .then((res) => {
      ElMessage.success('下发更新成功, 请等60秒后刷新列表');
    })
    .catch(() => {
      ElMessage.error('下发更新失败');
    });
});
const upateDialog = ref<InstanceType<typeof JuouDangerDialog>>();

const operates = [
  {
    key: 'deviceUpdate',
    name: '更新版本',
    handler: (row: any) => {
      if (!/\d\.\d\.\d/u.test(row.version)) {
        ElMessage.warning('未获取到当前版本，请检查设备类型和设备状态，刷新列表重试');
        return;
      }
      upateDialog.value?.showDialog(row);
    },
  },

  {
    key: 'timerMod',
    name: '定时设置',
    handler: (row: any) => {
      timerSettingRef.value?.show(row.id);
    },
  },

  {
    key: 'openMod',
    name: '常开模式',
    handler: useBaseAsync(async(row: any) => {
      await useApi().deviceApi.deviceSetModDevice(row.id, DEVICEMODE.Open);
      ElMessage.success('成功');
      tableRef.value?.refresh();
    }),
  },
  {
    key: 'closeMod',
    name: '常闭模式',
    handler: useBaseAsync(async(row: any) => {
      await useApi().deviceApi.deviceSetModDevice(
        row.id,
        DEVICEMODE.Close,
      );
      ElMessage.success('成功');
      tableRef.value?.refresh();
    }),
  },
];

const topOperates: any[] = [
  {
    key: 'export',
    name: '导出',
    handler: () => {
      exportRef.value?.show(
        'deviceApi',
        'pageDeviceDevice',
        paramsLast,
        columns.value,
        10,
        tableDateLast.total,
      );
    },
  },
];

const loading = ref(false);


// eslint-disable-next-line max-lines-per-function
const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    paramsLast = {
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),

    };
    const res = await useApi().deviceApi.pageDeviceDevice({
      ...paramsLast,
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };
    tableDateLast = tableData;
    tableData.data = tableData.data.map((item) => {
      const statusRef = ref('加载中...');
      const versionRef = ref('加载中...');
      const modRef = ref('加载中...');
      useApi()
        .deviceApi.deviceStatusDevice(item.id, {
          headers: {
            'custom-error': 'true',
          },
        })
        .then((statusRes) => {
          statusRef.value = statusRes.data.data ? '在线' : '离线';
        })
        .catch(() => {
          statusRef.value = '-';
        });
      useApi()
        .deviceApi.deviceVersionDevice(item.id, {
          headers: {
            'custom-error': 'true',
          },
        })
        .then((versionRes) => {
          versionRef.value = versionRes.data.data;
        })
        .catch(() => {
          versionRef.value = '-';
        });
      useApi()
        .deviceApi.deviceGetModDevice(item.id, {
          headers: {
            'custom-error': 'true',
          },
        })
        .then((modRes) => {
          modRef.value = modRes.data.data === 'open' ? '打开' : '关闭';
        })
        .catch(() => {
          modRef.value = '-';
        });
      return {
        ...item,
        status: statusRef,
        version: versionRef,
        mod: modRef,
      };
    });
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.idHex})操作，请谨慎操作`;

const updateDangderInfo = (row: any) => `您正在进行更新(${row.idHex})操作，请保证设备通电稳定在线，下发更新后，请等待60秒刷新查看版本`;

const addFun = useBaseAsync(async(data: any) => {
  await useApi().deviceApi.addDeviceDevice(data);
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  await useApi().deviceApi.updateDeviceDevice(data);
  ElMessage.success('修改成功');
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().deviceApi.deleteDeviceDevice(data.id);
  ElMessage.success('删除成功');
});


init();

</script>
<style lang="scss" scoped>
</style>
