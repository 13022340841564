/* tslint:disable */
/* eslint-disable */
/**
 * juou.billiards - API文档
 * <b>注意：仅开启swagger选项有效，建议上线前关闭该选项</b>  
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AreaTypeAdminVo
 */
export interface AreaTypeAdminVo {
    /**
     * 
     * @type {number}
     * @memberof AreaTypeAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AreaTypeAdminVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AreaTypeAdminVo
     */
    'bgImg': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BASEMENUTYPEE = {
    Link: 'link',
    Dir: 'dir'
} as const;

export type BASEMENUTYPEE = typeof BASEMENUTYPEE[keyof typeof BASEMENUTYPEE];


/**
 * 
 * @export
 * @enum {string}
 */

export const BASEROUTETYPEE = {
    AdminPage: 'admin_page',
    CommonPage: 'common_page'
} as const;

export type BASEROUTETYPEE = typeof BASEROUTETYPEE[keyof typeof BASEROUTETYPEE];


/**
 * 
 * @export
 * @enum {string}
 */

export const BILLSTATUSE = {
    Prep: 'PREP',
    Complete: 'COMPLETE',
    Failed: 'FAILED'
} as const;

export type BILLSTATUSE = typeof BILLSTATUSE[keyof typeof BILLSTATUSE];


/**
 * 
 * @export
 * @enum {string}
 */

export const BILLTYPEE = {
    Pay: 'PAY',
    Hour: 'HOUR',
    Meituan: 'MEITUAN',
    Douyin: 'DOUYIN',
    Cashpledge: 'CASHPLEDGE'
} as const;

export type BILLTYPEE = typeof BILLTYPEE[keyof typeof BILLTYPEE];


/**
 * 
 * @export
 * @interface BaseApiAdminVo
 */
export interface BaseApiAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseApiAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'swaggerPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'tagName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseApiAdminVo
     */
    'allowAll': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseApiAdminVo
     */
    'enable': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseApiAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof BaseApiAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseLogAdminVo
 */
export interface BaseLogAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseLogAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseLogAdminVo
     */
    'end'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseLogAdminVo
     */
    'startId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseLogAdminVo
     */
    'endId'?: number;
}
/**
 * 
 * @export
 * @interface BaseMenuAdminVo
 */
export interface BaseMenuAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseMenuAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseMenuAdminVo
     */
    'url'?: string;
    /**
     * 
     * @type {BASEMENUTYPEE}
     * @memberof BaseMenuAdminVo
     */
    'type': BASEMENUTYPEE;
    /**
     * 
     * @type {string}
     * @memberof BaseMenuAdminVo
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BaseMenuAdminVo
     */
    'icon'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMenuAdminVo
     */
    'sequence': number;
    /**
     * 
     * @type {boolean}
     * @memberof BaseMenuAdminVo
     */
    'enable': boolean;
    /**
     * 
     * @type {number}
     * @memberof BaseMenuAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseMenuAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseMenuAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseMenuAdminVo
     */
    'routeIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface BaseOperateAdminVo
 */
export interface BaseOperateAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseOperateAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseOperateAdminVo
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof BaseOperateAdminVo
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseOperateAdminVo
     */
    'roleIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof BaseOperateAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseOrganizationAdminVo
 */
export interface BaseOrganizationAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseOrganizationAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseOrganizationAdminVo
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseOrganizationAdminVo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BaseOrganizationAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseOrganizationAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BasePageQueryVo
 */
export interface BasePageQueryVo {
    /**
     * 
     * @type {number}
     * @memberof BasePageQueryVo
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof BasePageQueryVo
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BaseResultVo
 */
export interface BaseResultVo {
    /**
     * 
     * @type {boolean}
     * @memberof BaseResultVo
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseResultVo
     */
    'msg'?: string;
    /**
     * 
     * @type {any}
     * @memberof BaseResultVo
     */
    'data'?: any;
}
/**
 * 
 * @export
 * @interface BaseRoleAdminVo
 */
export interface BaseRoleAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseRoleAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseRoleAdminVo
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof BaseRoleAdminVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseRoleAdminVo
     */
    'orgCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseRoleAdminVo
     */
    'level'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseRoleAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface BaseRouteAdminVo
 */
export interface BaseRouteAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseRouteAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {BASEROUTETYPEE}
     * @memberof BaseRouteAdminVo
     */
    'type': BASEROUTETYPEE;
    /**
     * 
     * @type {boolean}
     * @memberof BaseRouteAdminVo
     */
    'allowAll': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'componentName': string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseRouteAdminVo
     */
    'isNest': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'redirect'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseRouteAdminVo
     */
    'enable': boolean;
    /**
     * 
     * @type {number}
     * @memberof BaseRouteAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseRouteAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseRouteAdminVo
     */
    'roleIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface BaseUserAdminVo
 */
export interface BaseUserAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseUserAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseUserAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseUserAdminVo
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserAdminVo
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {any}
     * @memberof BaseUserAdminVo
     */
    'meta'?: any;
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseUserAdminVo
     */
    'roleIds': Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseUserAdminVo
     */
    'orgCodes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface BaseUserRoleAdminVo
 */
export interface BaseUserRoleAdminVo {
    /**
     * 
     * @type {number}
     * @memberof BaseUserRoleAdminVo
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof BaseUserRoleAdminVo
     */
    'orgCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseUserRoleAdminVo
     */
    'roleId': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DEVICEMODE = {
    Open: 'open',
    Control: 'control',
    Close: 'close'
} as const;

export type DEVICEMODE = typeof DEVICEMODE[keyof typeof DEVICEMODE];


/**
 * 
 * @export
 * @interface DeviceAdminVo
 */
export interface DeviceAdminVo {
    /**
     * 
     * @type {number}
     * @memberof DeviceAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceAdminVo
     */
    'idHex'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAdminVo
     */
    'serialNumber': string;
    /**
     * 
     * @type {number}
     * @memberof DeviceAdminVo
     */
    'deviceTypeId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviceAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface DeviceLinkVo
 */
export interface DeviceLinkVo {
    /**
     * 
     * @type {number}
     * @memberof DeviceLinkVo
     */
    'id'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeviceLinkVo
     */
    'sourceDeviceIds'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceLinkVo
     */
    'sourceDeviceIdsHex': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeviceLinkVo
     */
    'slaveDeviceIds'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceLinkVo
     */
    'slaveDeviceIdsHex': Array<string>;
    /**
     * 
     * @type {DEVICEMODE}
     * @memberof DeviceLinkVo
     */
    'actionMod': DEVICEMODE;
    /**
     * 
     * @type {LINKTYPEE}
     * @memberof DeviceLinkVo
     */
    'linkType': LINKTYPEE;
    /**
     * 
     * @type {any}
     * @memberof DeviceLinkVo
     */
    'meta'?: any;
    /**
     * 
     * @type {string}
     * @memberof DeviceLinkVo
     */
    'createTime'?: string;
}


/**
 * 
 * @export
 * @interface DouyinPackageAdminVo
 */
export interface DouyinPackageAdminVo {
    /**
     * 
     * @type {number}
     * @memberof DouyinPackageAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DouyinPackageAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof DouyinPackageAdminVo
     */
    'typeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DouyinPackageAdminVo
     */
    'skuId': string;
    /**
     * 
     * @type {string}
     * @memberof DouyinPackageAdminVo
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof DouyinPackageAdminVo
     */
    'sec'?: number;
    /**
     * 
     * @type {string}
     * @memberof DouyinPackageAdminVo
     */
    'userange'?: string;
    /**
     * 
     * @type {string}
     * @memberof DouyinPackageAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface HourPackageAdminVo
 */
export interface HourPackageAdminVo {
    /**
     * 
     * @type {number}
     * @memberof HourPackageAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof HourPackageAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof HourPackageAdminVo
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof HourPackageAdminVo
     */
    'sequence': number;
    /**
     * 
     * @type {string}
     * @memberof HourPackageAdminVo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof HourPackageAdminVo
     */
    'sec': number;
    /**
     * 
     * @type {string}
     * @memberof HourPackageAdminVo
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof HourPackageAdminVo
     */
    'lmt': number;
    /**
     * 
     * @type {boolean}
     * @memberof HourPackageAdminVo
     */
    'hide': boolean;
    /**
     * 
     * @type {number}
     * @memberof HourPackageAdminVo
     */
    'saleCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof HourPackageAdminVo
     */
    'sumCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof HourPackageAdminVo
     */
    'usedCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof HourPackageAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LINKTYPEE = {
    Any: 'any',
    All: 'all'
} as const;

export type LINKTYPEE = typeof LINKTYPEE[keyof typeof LINKTYPEE];


/**
 * 
 * @export
 * @interface MeituanPackageAdminVo
 */
export interface MeituanPackageAdminVo {
    /**
     * 
     * @type {number}
     * @memberof MeituanPackageAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MeituanPackageAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof MeituanPackageAdminVo
     */
    'typeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MeituanPackageAdminVo
     */
    'dealgroupId': string;
    /**
     * 
     * @type {string}
     * @memberof MeituanPackageAdminVo
     */
    'dealId': string;
    /**
     * 
     * @type {string}
     * @memberof MeituanPackageAdminVo
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof MeituanPackageAdminVo
     */
    'sec'?: number;
    /**
     * 
     * @type {string}
     * @memberof MeituanPackageAdminVo
     */
    'userange'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeituanPackageAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PageQueryVo
 */
export interface PageQueryVo {
    /**
     * 
     * @type {number}
     * @memberof PageQueryVo
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PageQueryVo
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface PartialBaseApiAdminVo
 */
export interface PartialBaseApiAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseApiAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'swaggerPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'tagName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseApiAdminVo
     */
    'allowAll'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseApiAdminVo
     */
    'enable'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseApiAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseApiAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseLogAdminVo
 */
export interface PartialBaseLogAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseLogAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'level'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseLogAdminVo
     */
    'end'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseLogAdminVo
     */
    'startId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseLogAdminVo
     */
    'endId'?: number;
}
/**
 * 
 * @export
 * @interface PartialBaseMenuAdminVo
 */
export interface PartialBaseMenuAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseMenuAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseMenuAdminVo
     */
    'url'?: string;
    /**
     * 
     * @type {BASEMENUTYPEE}
     * @memberof PartialBaseMenuAdminVo
     */
    'type'?: BASEMENUTYPEE;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseMenuAdminVo
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseMenuAdminVo
     */
    'icon'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseMenuAdminVo
     */
    'sequence'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseMenuAdminVo
     */
    'enable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseMenuAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseMenuAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseMenuAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseMenuAdminVo
     */
    'routeIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface PartialBaseOperateAdminVo
 */
export interface PartialBaseOperateAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseOperateAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOperateAdminVo
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOperateAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseOperateAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOperateAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseOrganizationAdminVo
 */
export interface PartialBaseOrganizationAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseOrganizationAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseRoleAdminVo
 */
export interface PartialBaseRoleAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseRoleAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRoleAdminVo
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRoleAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRoleAdminVo
     */
    'orgCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseRoleAdminVo
     */
    'level'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRoleAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseRouteAdminVo
 */
export interface PartialBaseRouteAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseRouteAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {BASEROUTETYPEE}
     * @memberof PartialBaseRouteAdminVo
     */
    'type'?: BASEROUTETYPEE;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseRouteAdminVo
     */
    'allowAll'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'componentName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseRouteAdminVo
     */
    'isNest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'redirect'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartialBaseRouteAdminVo
     */
    'enable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseRouteAdminVo
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseRouteAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseRouteAdminVo
     */
    'roleIds'?: Array<number>;
}


/**
 * 
 * @export
 * @interface PartialBaseScheduleAdminVo
 */
export interface PartialBaseScheduleAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseScheduleAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseScheduleAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleInterval'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleCurrent'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseScheduleAdminVo
     */
    'ruleNextTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseScheduleAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialBaseUserAdminVo
 */
export interface PartialBaseUserAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBaseUserAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBaseUserAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseUserAdminVo
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseUserAdminVo
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBaseUserAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {any}
     * @memberof PartialBaseUserAdminVo
     */
    'meta'?: any;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartialBaseUserAdminVo
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartialBaseUserAdminVo
     */
    'orgCodes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PartialBillAdminVo
 */
export interface PartialBillAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialBillAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialBillAdminVo
     */
    'storeId'?: number;
    /**
     * 
     * @type {any}
     * @memberof PartialBillAdminVo
     */
    'meta'?: any;
    /**
     * 
     * @type {number}
     * @memberof PartialBillAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialBillAdminVo
     */
    'openid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBillAdminVo
     */
    'payMchid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBillAdminVo
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBillAdminVo
     */
    'backPrice'?: string;
    /**
     * 
     * @type {BILLTYPEE}
     * @memberof PartialBillAdminVo
     */
    'type'?: BILLTYPEE;
    /**
     * 
     * @type {BILLSTATUSE}
     * @memberof PartialBillAdminVo
     */
    'status'?: BILLSTATUSE;
    /**
     * 
     * @type {string}
     * @memberof PartialBillAdminVo
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBillAdminVo
     */
    'createTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBillAdminVo
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBillAdminVo
     */
    'end'?: string;
}


/**
 * 
 * @export
 * @interface PartialDeviceAdminVo
 */
export interface PartialDeviceAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialDeviceAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialDeviceAdminVo
     */
    'idHex'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialDeviceAdminVo
     */
    'serialNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialDeviceAdminVo
     */
    'deviceTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialDeviceAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialDeviceAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialHourPackageAdminVo
 */
export interface PartialHourPackageAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialHourPackageAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialHourPackageAdminVo
     */
    'storeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialHourPackageAdminVo
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialHourPackageAdminVo
     */
    'sequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialHourPackageAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialHourPackageAdminVo
     */
    'sec'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialHourPackageAdminVo
     */
    'price'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialHourPackageAdminVo
     */
    'lmt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartialHourPackageAdminVo
     */
    'hide'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartialHourPackageAdminVo
     */
    'saleCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialHourPackageAdminVo
     */
    'sumCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialHourPackageAdminVo
     */
    'usedCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialHourPackageAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialHourTicketAdminVo
 */
export interface PartialHourTicketAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialHourTicketAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialHourTicketAdminVo
     */
    'packageId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartialHourTicketAdminVo
     */
    'used'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartialHourTicketAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialHourTicketAdminVo
     */
    'storeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialHourTicketAdminVo
     */
    'typeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialHourTicketAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialHourTicketAdminVo
     */
    'sec'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialHourTicketAdminVo
     */
    'expireAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialHourTicketAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialPayPackageAdminVo
 */
export interface PartialPayPackageAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialPayPackageAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialPayPackageAdminVo
     */
    'storeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialPayPackageAdminVo
     */
    'sequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialPayPackageAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialPayPackageAdminVo
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialPayPackageAdminVo
     */
    'freePrice'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialPayPackageAdminVo
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialPayPackageAdminVo
     */
    'endTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialPayPackageAdminVo
     */
    'count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartialPayPackageAdminVo
     */
    'hide'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartialPayPackageAdminVo
     */
    'lmt'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialPayPackageAdminVo
     */
    'saleCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialPayPackageAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialRecordAdminVo
 */
export interface PartialRecordAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAdminVo
     */
    'storeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAdminVo
     */
    'storeDeviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialRecordAdminVo
     */
    'storeDeviceName'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAdminVo
     */
    'level'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialRecordAdminVo
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialRecordAdminVo
     */
    'msg'?: string;
    /**
     * 
     * @type {any}
     * @memberof PartialRecordAdminVo
     */
    'meta'?: any;
    /**
     * 
     * @type {string}
     * @memberof PartialRecordAdminVo
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialRecordAdminVo
     */
    'end'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialRecordAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialStoreAdminVo
 */
export interface PartialStoreAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'province'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'inx'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'logo'?: string;
    /**
     * 
     * @type {STORESTATUSE}
     * @memberof PartialStoreAdminVo
     */
    'status'?: STORESTATUSE;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreAdminVo
     */
    'tel'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'address'?: string;
    /**
     * 
     * @type {object}
     * @memberof PartialStoreAdminVo
     */
    'geo'?: object;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'detailAddress'?: string;
    /**
     * 
     * @type {any}
     * @memberof PartialStoreAdminVo
     */
    'meituan'?: any;
    /**
     * 
     * @type {any}
     * @memberof PartialStoreAdminVo
     */
    'douyin'?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof PartialStoreAdminVo
     */
    'vips'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'payMchid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreAdminVo
     */
    'createTime'?: string;
}


/**
 * 
 * @export
 * @interface PartialStoreCustomerAdminVo
 */
export interface PartialStoreCustomerAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialStoreCustomerAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreCustomerAdminVo
     */
    'storeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreCustomerAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreCustomerAdminVo
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreCustomerAdminVo
     */
    'freePrice'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreCustomerAdminVo
     */
    'level'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreCustomerAdminVo
     */
    'score'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreCustomerAdminVo
     */
    'allScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreCustomerAdminVo
     */
    'openCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreCustomerAdminVo
     */
    'payPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreCustomerAdminVo
     */
    'hourPrice'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreCustomerAdminVo
     */
    'hourCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreCustomerAdminVo
     */
    'meituanCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreCustomerAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PartialStoreManagerAdminVo
 */
export interface PartialStoreManagerAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PartialStoreManagerAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreManagerAdminVo
     */
    'storeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialStoreManagerAdminVo
     */
    'phone'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreManagerAdminVo
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialStoreManagerAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface PayPackageAdminVo
 */
export interface PayPackageAdminVo {
    /**
     * 
     * @type {number}
     * @memberof PayPackageAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayPackageAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof PayPackageAdminVo
     */
    'sequence': number;
    /**
     * 
     * @type {string}
     * @memberof PayPackageAdminVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PayPackageAdminVo
     */
    'price': string;
    /**
     * 
     * @type {string}
     * @memberof PayPackageAdminVo
     */
    'freePrice': string;
    /**
     * 
     * @type {number}
     * @memberof PayPackageAdminVo
     */
    'score'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayPackageAdminVo
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof PayPackageAdminVo
     */
    'count': number;
    /**
     * 
     * @type {boolean}
     * @memberof PayPackageAdminVo
     */
    'hide': boolean;
    /**
     * 
     * @type {number}
     * @memberof PayPackageAdminVo
     */
    'lmt': number;
    /**
     * 
     * @type {number}
     * @memberof PayPackageAdminVo
     */
    'saleCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayPackageAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface ResultVo
 */
export interface ResultVo {
    /**
     * 
     * @type {boolean}
     * @memberof ResultVo
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ResultVo
     */
    'msg'?: string;
    /**
     * 
     * @type {any}
     * @memberof ResultVo
     */
    'data'?: any;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const STORESTATUSE = {
    Prepare: 'prepare',
    Trial: 'trial',
    Run: 'run',
    Stop: 'STOP'
} as const;

export type STORESTATUSE = typeof STORESTATUSE[keyof typeof STORESTATUSE];


/**
 * 
 * @export
 * @interface StoreAdminVo
 */
export interface StoreAdminVo {
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'remark'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'province': string;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'inx'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'logo'?: string;
    /**
     * 
     * @type {STORESTATUSE}
     * @memberof StoreAdminVo
     */
    'status': STORESTATUSE;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminVo
     */
    'tel'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'address'?: string;
    /**
     * 
     * @type {object}
     * @memberof StoreAdminVo
     */
    'geo'?: object;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'detailAddress'?: string;
    /**
     * 
     * @type {any}
     * @memberof StoreAdminVo
     */
    'meituan'?: any;
    /**
     * 
     * @type {any}
     * @memberof StoreAdminVo
     */
    'douyin'?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof StoreAdminVo
     */
    'vips'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'payMchid'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminVo
     */
    'createTime'?: string;
}


/**
 * 
 * @export
 * @interface StoreComDeviceAdminVo
 */
export interface StoreComDeviceAdminVo {
    /**
     * 
     * @type {number}
     * @memberof StoreComDeviceAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreComDeviceAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof StoreComDeviceAdminVo
     */
    'category'?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreComDeviceAdminVo
     */
    'deviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreComDeviceAdminVo
     */
    'deviceIdHex': string;
    /**
     * 
     * @type {string}
     * @memberof StoreComDeviceAdminVo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof StoreComDeviceAdminVo
     */
    'sequence': number;
    /**
     * 
     * @type {string}
     * @memberof StoreComDeviceAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface StoreDeviceAdminVo
 */
export interface StoreDeviceAdminVo {
    /**
     * 
     * @type {number}
     * @memberof StoreDeviceAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreDeviceAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof StoreDeviceAdminVo
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreDeviceAdminVo
     */
    'deviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreDeviceAdminVo
     */
    'deviceIdHex': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof StoreDeviceAdminVo
     */
    'collDeviceIds'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreDeviceAdminVo
     */
    'collDeviceIdsHex'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StoreDeviceAdminVo
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof StoreDeviceAdminVo
     */
    'sequence': number;
    /**
     * 
     * @type {string}
     * @memberof StoreDeviceAdminVo
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreDeviceAdminVo
     */
    'cashPledgePrice'?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreDeviceAdminVo
     */
    'customPhone'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreDeviceAdminVo
     */
    'customEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreDeviceAdminVo
     */
    'customStartTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreDeviceAdminVo
     */
    'customBillId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreDeviceAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface StoreDoorDeviceAdminVo
 */
export interface StoreDoorDeviceAdminVo {
    /**
     * 
     * @type {number}
     * @memberof StoreDoorDeviceAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreDoorDeviceAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof StoreDoorDeviceAdminVo
     */
    'deviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreDoorDeviceAdminVo
     */
    'deviceIdHex': string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreDoorDeviceAdminVo
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreDoorDeviceAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface StoreManagerAdminVo
 */
export interface StoreManagerAdminVo {
    /**
     * 
     * @type {number}
     * @memberof StoreManagerAdminVo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreManagerAdminVo
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof StoreManagerAdminVo
     */
    'phone': number;
    /**
     * 
     * @type {string}
     * @memberof StoreManagerAdminVo
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreManagerAdminVo
     */
    'createTime'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject1
 */
export interface SystemGenerateObject1 {
    /**
     * 
     * @type {PartialBaseApiAdminVo}
     * @memberof SystemGenerateObject1
     */
    'conditions': PartialBaseApiAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject10
 */
export interface SystemGenerateObject10 {
    /**
     * 
     * @type {PartialBaseRoleAdminVo}
     * @memberof SystemGenerateObject10
     */
    'conditions': PartialBaseRoleAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject11
 */
export interface SystemGenerateObject11 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject11
     */
    'userId': number;
    /**
     * 
     * @type {Array<BaseUserRoleAdminVo>}
     * @memberof SystemGenerateObject11
     */
    'userRoleVos': Array<BaseUserRoleAdminVo>;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject12
 */
export interface SystemGenerateObject12 {
    /**
     * 
     * @type {PartialBaseRoleAdminVo}
     * @memberof SystemGenerateObject12
     */
    'conditions': PartialBaseRoleAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject13
 */
export interface SystemGenerateObject13 {
    /**
     * 
     * @type {PartialBaseRoleAdminVo}
     * @memberof SystemGenerateObject13
     */
    'conditions': PartialBaseRoleAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject13
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject14
 */
export interface SystemGenerateObject14 {
    /**
     * 
     * @type {PartialBaseUserAdminVo}
     * @memberof SystemGenerateObject14
     */
    'conditions': PartialBaseUserAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject15
 */
export interface SystemGenerateObject15 {
    /**
     * 
     * @type {PartialBaseUserAdminVo}
     * @memberof SystemGenerateObject15
     */
    'conditions': PartialBaseUserAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject15
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject16
 */
export interface SystemGenerateObject16 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject16
     */
    'oldPw': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject16
     */
    'newPw': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject17
 */
export interface SystemGenerateObject17 {
    /**
     * 
     * @type {PartialBaseOrganizationAdminVo}
     * @memberof SystemGenerateObject17
     */
    'conditions': PartialBaseOrganizationAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject18
 */
export interface SystemGenerateObject18 {
    /**
     * 
     * @type {PartialBaseOrganizationAdminVo}
     * @memberof SystemGenerateObject18
     */
    'conditions': PartialBaseOrganizationAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject18
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject19
 */
export interface SystemGenerateObject19 {
    /**
     * 
     * @type {PartialBaseLogAdminVo}
     * @memberof SystemGenerateObject19
     */
    'conditions': PartialBaseLogAdminVo;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject19
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof SystemGenerateObject19
     */
    'isGreater': boolean;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject2
 */
export interface SystemGenerateObject2 {
    /**
     * 
     * @type {PartialBaseMenuAdminVo}
     * @memberof SystemGenerateObject2
     */
    'conditions': PartialBaseMenuAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject20
 */
export interface SystemGenerateObject20 {
    /**
     * 
     * @type {PartialBaseLogAdminVo}
     * @memberof SystemGenerateObject20
     */
    'conditions': PartialBaseLogAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject21
 */
export interface SystemGenerateObject21 {
    /**
     * 
     * @type {PartialBaseLogAdminVo}
     * @memberof SystemGenerateObject21
     */
    'conditions': PartialBaseLogAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject21
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject22
 */
export interface SystemGenerateObject22 {
    /**
     * 
     * @type {PartialDeviceAdminVo}
     * @memberof SystemGenerateObject22
     */
    'conditions': PartialDeviceAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject23
 */
export interface SystemGenerateObject23 {
    /**
     * 
     * @type {PartialDeviceAdminVo}
     * @memberof SystemGenerateObject23
     */
    'conditions': PartialDeviceAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject23
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject24
 */
export interface SystemGenerateObject24 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject24
     */
    'deviceId': number;
    /**
     * 
     * @type {DEVICEMODE}
     * @memberof SystemGenerateObject24
     */
    'mod': DEVICEMODE;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject24
     */
    'base': string;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject24
     */
    'interval': number;
}


/**
 * 
 * @export
 * @interface SystemGenerateObject25
 */
export interface SystemGenerateObject25 {
    /**
     * 
     * @type {PartialStoreAdminVo}
     * @memberof SystemGenerateObject25
     */
    'conditions': PartialStoreAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject26
 */
export interface SystemGenerateObject26 {
    /**
     * 
     * @type {PartialStoreAdminVo}
     * @memberof SystemGenerateObject26
     */
    'conditions': PartialStoreAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject26
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject27
 */
export interface SystemGenerateObject27 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject27
     */
    'longtitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject27
     */
    'latitude'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject28
 */
export interface SystemGenerateObject28 {
    /**
     * 
     * @type {PartialStoreCustomerAdminVo}
     * @memberof SystemGenerateObject28
     */
    'conditions': PartialStoreCustomerAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject29
 */
export interface SystemGenerateObject29 {
    /**
     * 
     * @type {PartialStoreCustomerAdminVo}
     * @memberof SystemGenerateObject29
     */
    'conditions': PartialStoreCustomerAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject29
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject3
 */
export interface SystemGenerateObject3 {
    /**
     * 
     * @type {PartialBaseMenuAdminVo}
     * @memberof SystemGenerateObject3
     */
    'conditions': PartialBaseMenuAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject3
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject30
 */
export interface SystemGenerateObject30 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject30
     */
    'storeDeviceId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject30
     */
    'openid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject31
 */
export interface SystemGenerateObject31 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject31
     */
    'storeDeviceId': number;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject31
     */
    'billId': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject32
 */
export interface SystemGenerateObject32 {
    /**
     * 
     * @type {PartialStoreManagerAdminVo}
     * @memberof SystemGenerateObject32
     */
    'conditions': PartialStoreManagerAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject33
 */
export interface SystemGenerateObject33 {
    /**
     * 
     * @type {PartialStoreManagerAdminVo}
     * @memberof SystemGenerateObject33
     */
    'conditions': PartialStoreManagerAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject33
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject34
 */
export interface SystemGenerateObject34 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject34
     */
    'storeDeviceId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject34
     */
    'deviceIdHex': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject36
 */
export interface SystemGenerateObject36 {
    /**
     * 
     * @type {PartialPayPackageAdminVo}
     * @memberof SystemGenerateObject36
     */
    'conditions': PartialPayPackageAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject37
 */
export interface SystemGenerateObject37 {
    /**
     * 
     * @type {PartialPayPackageAdminVo}
     * @memberof SystemGenerateObject37
     */
    'conditions': PartialPayPackageAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject37
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject38
 */
export interface SystemGenerateObject38 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject38
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject38
     */
    'payPackageId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject38
     */
    'openid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject39
 */
export interface SystemGenerateObject39 {
    /**
     * 
     * @type {PartialHourPackageAdminVo}
     * @memberof SystemGenerateObject39
     */
    'conditions': PartialHourPackageAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject4
 */
export interface SystemGenerateObject4 {
    /**
     * 
     * @type {PartialBaseOperateAdminVo}
     * @memberof SystemGenerateObject4
     */
    'conditions': PartialBaseOperateAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject40
 */
export interface SystemGenerateObject40 {
    /**
     * 
     * @type {PartialHourPackageAdminVo}
     * @memberof SystemGenerateObject40
     */
    'conditions': PartialHourPackageAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject40
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject41
 */
export interface SystemGenerateObject41 {
    /**
     * 
     * @type {PartialHourTicketAdminVo}
     * @memberof SystemGenerateObject41
     */
    'conditions': PartialHourTicketAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject42
 */
export interface SystemGenerateObject42 {
    /**
     * 
     * @type {PartialHourTicketAdminVo}
     * @memberof SystemGenerateObject42
     */
    'conditions': PartialHourTicketAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject42
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject43
 */
export interface SystemGenerateObject43 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject43
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject43
     */
    'packageId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject43
     */
    'expireAt'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject44
 */
export interface SystemGenerateObject44 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject44
     */
    'packageId': number;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject44
     */
    'phone': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject44
     */
    'expireAt'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject45
 */
export interface SystemGenerateObject45 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject45
     */
    'storeDeviceId': number;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject45
     */
    'hourTicketId': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject46
 */
export interface SystemGenerateObject46 {
    /**
     * 
     * @type {Array<number>}
     * @memberof SystemGenerateObject46
     */
    'ticketIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject46
     */
    'expireAt'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject47
 */
export interface SystemGenerateObject47 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject47
     */
    'storeDeviceId': number;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject47
     */
    'hourPackageId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject47
     */
    'openid': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject48
 */
export interface SystemGenerateObject48 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject48
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject48
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject48
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject49
 */
export interface SystemGenerateObject49 {
    /**
     * 
     * @type {PartialBillAdminVo}
     * @memberof SystemGenerateObject49
     */
    'conditions': PartialBillAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject5
 */
export interface SystemGenerateObject5 {
    /**
     * 
     * @type {PartialBaseOperateAdminVo}
     * @memberof SystemGenerateObject5
     */
    'conditions': PartialBaseOperateAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject5
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject50
 */
export interface SystemGenerateObject50 {
    /**
     * 
     * @type {PartialBillAdminVo}
     * @memberof SystemGenerateObject50
     */
    'conditions': PartialBillAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject50
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject51
 */
export interface SystemGenerateObject51 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject51
     */
    'billId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject51
     */
    'backPrice': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject51
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject52
 */
export interface SystemGenerateObject52 {
    /**
     * 
     * @type {PartialRecordAdminVo}
     * @memberof SystemGenerateObject52
     */
    'conditions': PartialRecordAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject53
 */
export interface SystemGenerateObject53 {
    /**
     * 
     * @type {PartialRecordAdminVo}
     * @memberof SystemGenerateObject53
     */
    'conditions': PartialRecordAdminVo;
    /**
     * 
     * @type {PageQueryVo}
     * @memberof SystemGenerateObject53
     */
    'pageQuery': PageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject54
 */
export interface SystemGenerateObject54 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject54
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject54
     */
    'authCode': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject54
     */
    'redirectUrl': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject55
 */
export interface SystemGenerateObject55 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject55
     */
    'storeDeviceId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject55
     */
    'meituanTickCode': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject56
 */
export interface SystemGenerateObject56 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject56
     */
    'loginCode': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject57
 */
export interface SystemGenerateObject57 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject57
     */
    'loginCode': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject57
     */
    'phoneCode': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject58
 */
export interface SystemGenerateObject58 {
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject58
     */
    'event': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject58
     */
    'client_key': string;
    /**
     * 
     * @type {any}
     * @memberof SystemGenerateObject58
     */
    'content': any;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject59
 */
export interface SystemGenerateObject59 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject59
     */
    'storeDeviceId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject59
     */
    'douyinTickQrCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject59
     */
    'douyinTickCode'?: string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject6
 */
export interface SystemGenerateObject6 {
    /**
     * 
     * @type {PartialBaseRouteAdminVo}
     * @memberof SystemGenerateObject6
     */
    'conditions': PartialBaseRouteAdminVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject60
 */
export interface SystemGenerateObject60 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject60
     */
    'phone': number;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject61
 */
export interface SystemGenerateObject61 {
    /**
     * 
     * @type {number}
     * @memberof SystemGenerateObject61
     */
    'phone': number;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject61
     */
    'phoneCode': string;
    /**
     * 
     * @type {string}
     * @memberof SystemGenerateObject61
     */
    'loginCode': string;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject62
 */
export interface SystemGenerateObject62 {
    /**
     * 
     * @type {any}
     * @memberof SystemGenerateObject62
     */
    'meta': any;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject7
 */
export interface SystemGenerateObject7 {
    /**
     * 
     * @type {PartialBaseRouteAdminVo}
     * @memberof SystemGenerateObject7
     */
    'conditions': PartialBaseRouteAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject7
     */
    'pageQuery': BasePageQueryVo;
}
/**
 * 
 * @export
 * @interface SystemGenerateObject8
 */
export interface SystemGenerateObject8 {
    /**
     * 
     * @type {PartialBaseScheduleAdminVo}
     * @memberof SystemGenerateObject8
     */
    'conditions': PartialBaseScheduleAdminVo;
    /**
     * 
     * @type {BasePageQueryVo}
     * @memberof SystemGenerateObject8
     */
    'pageQuery': BasePageQueryVo;
}

/**
 * AreatypeApi - axios parameter creator
 * @export
 */
export const AreatypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加店铺设备类型
         * @param {AreaTypeAdminVo} areaTypeAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAreaTypeareatype: async (areaTypeAdminVo: AreaTypeAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaTypeAdminVo' is not null or undefined
            assertParamExists('addAreaTypeareatype', 'areaTypeAdminVo', areaTypeAdminVo)
            const localVarPath = `/areatype/addAreaType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(areaTypeAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除店铺设备类型
         * @param {number} areaTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delAreaTypeareatype: async (areaTypeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaTypeId' is not null or undefined
            assertParamExists('delAreaTypeareatype', 'areaTypeId', areaTypeId)
            const localVarPath = `/areatype/delAreaType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (areaTypeId !== undefined) {
                localVarQueryParameter['areaTypeId'] = areaTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取所有店铺设备类型
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAreaTypeareatype: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/areatype/listAreaType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取客户端门店设备列表类型列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientAreaTypeareatype: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/areatype/listClientAreaType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新店铺设备类型
         * @param {AreaTypeAdminVo} areaTypeAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAreaTypeareatype: async (areaTypeAdminVo: AreaTypeAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaTypeAdminVo' is not null or undefined
            assertParamExists('updateAreaTypeareatype', 'areaTypeAdminVo', areaTypeAdminVo)
            const localVarPath = `/areatype/updateAreaType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(areaTypeAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AreatypeApi - functional programming interface
 * @export
 */
export const AreatypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AreatypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加店铺设备类型
         * @param {AreaTypeAdminVo} areaTypeAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAreaTypeareatype(areaTypeAdminVo: AreaTypeAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAreaTypeareatype(areaTypeAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除店铺设备类型
         * @param {number} areaTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delAreaTypeareatype(areaTypeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delAreaTypeareatype(areaTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取所有店铺设备类型
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAreaTypeareatype(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAreaTypeareatype(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取客户端门店设备列表类型列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClientAreaTypeareatype(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClientAreaTypeareatype(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新店铺设备类型
         * @param {AreaTypeAdminVo} areaTypeAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAreaTypeareatype(areaTypeAdminVo: AreaTypeAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAreaTypeareatype(areaTypeAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AreatypeApi - factory interface
 * @export
 */
export const AreatypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AreatypeApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加店铺设备类型
         * @param {AreaTypeAdminVo} areaTypeAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAreaTypeareatype(areaTypeAdminVo: AreaTypeAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addAreaTypeareatype(areaTypeAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除店铺设备类型
         * @param {number} areaTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delAreaTypeareatype(areaTypeId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delAreaTypeareatype(areaTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取所有店铺设备类型
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAreaTypeareatype(options?: any): AxiosPromise<any> {
            return localVarFp.listAreaTypeareatype(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取客户端门店设备列表类型列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientAreaTypeareatype(options?: any): AxiosPromise<any> {
            return localVarFp.listClientAreaTypeareatype(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新店铺设备类型
         * @param {AreaTypeAdminVo} areaTypeAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAreaTypeareatype(areaTypeAdminVo: AreaTypeAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateAreaTypeareatype(areaTypeAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AreatypeApi - object-oriented interface
 * @export
 * @class AreatypeApi
 * @extends {BaseAPI}
 */
export class AreatypeApi extends BaseAPI {
    /**
     * 
     * @summary 添加店铺设备类型
     * @param {AreaTypeAdminVo} areaTypeAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreatypeApi
     */
    public addAreaTypeareatype(areaTypeAdminVo: AreaTypeAdminVo, options?: AxiosRequestConfig) {
        return AreatypeApiFp(this.configuration).addAreaTypeareatype(areaTypeAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除店铺设备类型
     * @param {number} areaTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreatypeApi
     */
    public delAreaTypeareatype(areaTypeId: number, options?: AxiosRequestConfig) {
        return AreatypeApiFp(this.configuration).delAreaTypeareatype(areaTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取所有店铺设备类型
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreatypeApi
     */
    public listAreaTypeareatype(options?: AxiosRequestConfig) {
        return AreatypeApiFp(this.configuration).listAreaTypeareatype(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取客户端门店设备列表类型列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreatypeApi
     */
    public listClientAreaTypeareatype(options?: AxiosRequestConfig) {
        return AreatypeApiFp(this.configuration).listClientAreaTypeareatype(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新店铺设备类型
     * @param {AreaTypeAdminVo} areaTypeAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreatypeApi
     */
    public updateAreaTypeareatype(areaTypeAdminVo: AreaTypeAdminVo, options?: AxiosRequestConfig) {
        return AreatypeApiFp(this.configuration).updateAreaTypeareatype(areaTypeAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseApiApi - axios parameter creator
 * @export
 */
export const BaseApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取接口列表
         * @param {SystemGenerateObject1} systemGenerateObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApisBaseApi: async (systemGenerateObject1: SystemGenerateObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject1' is not null or undefined
            assertParamExists('listApisBaseApi', 'systemGenerateObject1', systemGenerateObject1)
            const localVarPath = `/__base/api/listApis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改接口
         * @param {BaseApiAdminVo} baseApiAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApiBaseApi: async (baseApiAdminVo: BaseApiAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseApiAdminVo' is not null or undefined
            assertParamExists('updateApiBaseApi', 'baseApiAdminVo', baseApiAdminVo)
            const localVarPath = `/__base/api/updateApi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseApiAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseApiApi - functional programming interface
 * @export
 */
export const BaseApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取接口列表
         * @param {SystemGenerateObject1} systemGenerateObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listApisBaseApi(systemGenerateObject1: SystemGenerateObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listApisBaseApi(systemGenerateObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改接口
         * @param {BaseApiAdminVo} baseApiAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApiBaseApi(baseApiAdminVo: BaseApiAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApiBaseApi(baseApiAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseApiApi - factory interface
 * @export
 */
export const BaseApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseApiApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取接口列表
         * @param {SystemGenerateObject1} systemGenerateObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApisBaseApi(systemGenerateObject1: SystemGenerateObject1, options?: any): AxiosPromise<any> {
            return localVarFp.listApisBaseApi(systemGenerateObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改接口
         * @param {BaseApiAdminVo} baseApiAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApiBaseApi(baseApiAdminVo: BaseApiAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateApiBaseApi(baseApiAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseApiApi - object-oriented interface
 * @export
 * @class BaseApiApi
 * @extends {BaseAPI}
 */
export class BaseApiApi extends BaseAPI {
    /**
     * 
     * @summary 获取接口列表
     * @param {SystemGenerateObject1} systemGenerateObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApiApi
     */
    public listApisBaseApi(systemGenerateObject1: SystemGenerateObject1, options?: AxiosRequestConfig) {
        return BaseApiApiFp(this.configuration).listApisBaseApi(systemGenerateObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改接口
     * @param {BaseApiAdminVo} baseApiAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApiApi
     */
    public updateApiBaseApi(baseApiAdminVo: BaseApiAdminVo, options?: AxiosRequestConfig) {
        return BaseApiApiFp(this.configuration).updateApiBaseApi(baseApiAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseLogApi - axios parameter creator
 * @export
 */
export const BaseLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取某个id的位置
         * @param {SystemGenerateObject19} systemGenerateObject19 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountBaseLog: async (systemGenerateObject19: SystemGenerateObject19, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject19' is not null or undefined
            assertParamExists('getCountBaseLog', 'systemGenerateObject19', systemGenerateObject19)
            const localVarPath = `/__base/log/getCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject19, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject20} systemGenerateObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogBaseLog: async (systemGenerateObject20: SystemGenerateObject20, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject20' is not null or undefined
            assertParamExists('listLogBaseLog', 'systemGenerateObject20', systemGenerateObject20)
            const localVarPath = `/__base/log/listLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject20, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject21} systemGenerateObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageLogBaseLog: async (systemGenerateObject21: SystemGenerateObject21, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject21' is not null or undefined
            assertParamExists('pageLogBaseLog', 'systemGenerateObject21', systemGenerateObject21)
            const localVarPath = `/__base/log/pageLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject21, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseLogAdminVo} baseLogAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogBaseLog: async (baseLogAdminVo: BaseLogAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseLogAdminVo' is not null or undefined
            assertParamExists('updateLogBaseLog', 'baseLogAdminVo', baseLogAdminVo)
            const localVarPath = `/__base/log/updateLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseLogAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseLogApi - functional programming interface
 * @export
 */
export const BaseLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取某个id的位置
         * @param {SystemGenerateObject19} systemGenerateObject19 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountBaseLog(systemGenerateObject19: SystemGenerateObject19, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountBaseLog(systemGenerateObject19, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject20} systemGenerateObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLogBaseLog(systemGenerateObject20: SystemGenerateObject20, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLogBaseLog(systemGenerateObject20, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject21} systemGenerateObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageLogBaseLog(systemGenerateObject21: SystemGenerateObject21, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageLogBaseLog(systemGenerateObject21, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseLogAdminVo} baseLogAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLogBaseLog(baseLogAdminVo: BaseLogAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLogBaseLog(baseLogAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseLogApi - factory interface
 * @export
 */
export const BaseLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseLogApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取某个id的位置
         * @param {SystemGenerateObject19} systemGenerateObject19 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountBaseLog(systemGenerateObject19: SystemGenerateObject19, options?: any): AxiosPromise<any> {
            return localVarFp.getCountBaseLog(systemGenerateObject19, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject20} systemGenerateObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogBaseLog(systemGenerateObject20: SystemGenerateObject20, options?: any): AxiosPromise<any> {
            return localVarFp.listLogBaseLog(systemGenerateObject20, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject21} systemGenerateObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageLogBaseLog(systemGenerateObject21: SystemGenerateObject21, options?: any): AxiosPromise<any> {
            return localVarFp.pageLogBaseLog(systemGenerateObject21, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseLogAdminVo} baseLogAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogBaseLog(baseLogAdminVo: BaseLogAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateLogBaseLog(baseLogAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseLogApi - object-oriented interface
 * @export
 * @class BaseLogApi
 * @extends {BaseAPI}
 */
export class BaseLogApi extends BaseAPI {
    /**
     * 
     * @summary 获取某个id的位置
     * @param {SystemGenerateObject19} systemGenerateObject19 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseLogApi
     */
    public getCountBaseLog(systemGenerateObject19: SystemGenerateObject19, options?: AxiosRequestConfig) {
        return BaseLogApiFp(this.configuration).getCountBaseLog(systemGenerateObject19, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取操作列表
     * @param {SystemGenerateObject20} systemGenerateObject20 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseLogApi
     */
    public listLogBaseLog(systemGenerateObject20: SystemGenerateObject20, options?: AxiosRequestConfig) {
        return BaseLogApiFp(this.configuration).listLogBaseLog(systemGenerateObject20, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取操作列表(分页)
     * @param {SystemGenerateObject21} systemGenerateObject21 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseLogApi
     */
    public pageLogBaseLog(systemGenerateObject21: SystemGenerateObject21, options?: AxiosRequestConfig) {
        return BaseLogApiFp(this.configuration).pageLogBaseLog(systemGenerateObject21, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改操作
     * @param {BaseLogAdminVo} baseLogAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseLogApi
     */
    public updateLogBaseLog(baseLogAdminVo: BaseLogAdminVo, options?: AxiosRequestConfig) {
        return BaseLogApiFp(this.configuration).updateLogBaseLog(baseLogAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseMenuApi - axios parameter creator
 * @export
 */
export const BaseMenuApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMenuBaseMenu: async (baseMenuAdminVo: BaseMenuAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseMenuAdminVo' is not null or undefined
            assertParamExists('addMenuBaseMenu', 'baseMenuAdminVo', baseMenuAdminVo)
            const localVarPath = `/__base/menu/addMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseMenuAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除菜单
         * @param {number} menuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delMenuBaseMenu: async (menuId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'menuId' is not null or undefined
            assertParamExists('delMenuBaseMenu', 'menuId', menuId)
            const localVarPath = `/__base/menu/delMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (menuId !== undefined) {
                localVarQueryParameter['menuId'] = menuId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取动态菜单
         * @summary 获取动态菜单
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getsBaseMenu: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/menu/gets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取菜单列表
         * @param {SystemGenerateObject2} systemGenerateObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMenusBaseMenu: async (systemGenerateObject2: SystemGenerateObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject2' is not null or undefined
            assertParamExists('listMenusBaseMenu', 'systemGenerateObject2', systemGenerateObject2)
            const localVarPath = `/__base/menu/listMenus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取菜单列表(分页)
         * @param {SystemGenerateObject3} systemGenerateObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageMenusBaseMenu: async (systemGenerateObject3: SystemGenerateObject3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject3' is not null or undefined
            assertParamExists('pageMenusBaseMenu', 'systemGenerateObject3', systemGenerateObject3)
            const localVarPath = `/__base/menu/pageMenus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenuBaseMenu: async (baseMenuAdminVo: BaseMenuAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseMenuAdminVo' is not null or undefined
            assertParamExists('updateMenuBaseMenu', 'baseMenuAdminVo', baseMenuAdminVo)
            const localVarPath = `/__base/menu/updateMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseMenuAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseMenuApi - functional programming interface
 * @export
 */
export const BaseMenuApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseMenuApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMenuBaseMenu(baseMenuAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除菜单
         * @param {number} menuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delMenuBaseMenu(menuId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delMenuBaseMenu(menuId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取动态菜单
         * @summary 获取动态菜单
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getsBaseMenu(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getsBaseMenu(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取菜单列表
         * @param {SystemGenerateObject2} systemGenerateObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMenusBaseMenu(systemGenerateObject2: SystemGenerateObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMenusBaseMenu(systemGenerateObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取菜单列表(分页)
         * @param {SystemGenerateObject3} systemGenerateObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageMenusBaseMenu(systemGenerateObject3: SystemGenerateObject3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageMenusBaseMenu(systemGenerateObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMenuBaseMenu(baseMenuAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseMenuApi - factory interface
 * @export
 */
export const BaseMenuApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseMenuApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addMenuBaseMenu(baseMenuAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除菜单
         * @param {number} menuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delMenuBaseMenu(menuId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delMenuBaseMenu(menuId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取动态菜单
         * @summary 获取动态菜单
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getsBaseMenu(options?: any): AxiosPromise<any> {
            return localVarFp.getsBaseMenu(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取菜单列表
         * @param {SystemGenerateObject2} systemGenerateObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMenusBaseMenu(systemGenerateObject2: SystemGenerateObject2, options?: any): AxiosPromise<any> {
            return localVarFp.listMenusBaseMenu(systemGenerateObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取菜单列表(分页)
         * @param {SystemGenerateObject3} systemGenerateObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageMenusBaseMenu(systemGenerateObject3: SystemGenerateObject3, options?: any): AxiosPromise<any> {
            return localVarFp.pageMenusBaseMenu(systemGenerateObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改菜单
         * @param {BaseMenuAdminVo} baseMenuAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateMenuBaseMenu(baseMenuAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseMenuApi - object-oriented interface
 * @export
 * @class BaseMenuApi
 * @extends {BaseAPI}
 */
export class BaseMenuApi extends BaseAPI {
    /**
     * 
     * @summary 添加菜单
     * @param {BaseMenuAdminVo} baseMenuAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public addMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).addMenuBaseMenu(baseMenuAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除菜单
     * @param {number} menuId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public delMenuBaseMenu(menuId: number, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).delMenuBaseMenu(menuId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取动态菜单
     * @summary 获取动态菜单
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public getsBaseMenu(options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).getsBaseMenu(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取菜单列表
     * @param {SystemGenerateObject2} systemGenerateObject2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public listMenusBaseMenu(systemGenerateObject2: SystemGenerateObject2, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).listMenusBaseMenu(systemGenerateObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取菜单列表(分页)
     * @param {SystemGenerateObject3} systemGenerateObject3 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public pageMenusBaseMenu(systemGenerateObject3: SystemGenerateObject3, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).pageMenusBaseMenu(systemGenerateObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改菜单
     * @param {BaseMenuAdminVo} baseMenuAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseMenuApi
     */
    public updateMenuBaseMenu(baseMenuAdminVo: BaseMenuAdminVo, options?: AxiosRequestConfig) {
        return BaseMenuApiFp(this.configuration).updateMenuBaseMenu(baseMenuAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseOperateApi - axios parameter creator
 * @export
 */
export const BaseOperateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperateBaseOperate: async (baseOperateAdminVo: BaseOperateAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseOperateAdminVo' is not null or undefined
            assertParamExists('addOperateBaseOperate', 'baseOperateAdminVo', baseOperateAdminVo)
            const localVarPath = `/__base/operate/addOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOperateAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除操作
         * @param {number} operateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOperateBaseOperate: async (operateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operateId' is not null or undefined
            assertParamExists('delOperateBaseOperate', 'operateId', operateId)
            const localVarPath = `/__base/operate/delOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (operateId !== undefined) {
                localVarQueryParameter['operateId'] = operateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户的操作列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatesBaseOperate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/operate/getOperates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject4} systemGenerateObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperateBaseOperate: async (systemGenerateObject4: SystemGenerateObject4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject4' is not null or undefined
            assertParamExists('listOperateBaseOperate', 'systemGenerateObject4', systemGenerateObject4)
            const localVarPath = `/__base/operate/listOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject5} systemGenerateObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOperateBaseOperate: async (systemGenerateObject5: SystemGenerateObject5, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject5' is not null or undefined
            assertParamExists('pageOperateBaseOperate', 'systemGenerateObject5', systemGenerateObject5)
            const localVarPath = `/__base/operate/pageOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperateBaseOperate: async (baseOperateAdminVo: BaseOperateAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseOperateAdminVo' is not null or undefined
            assertParamExists('updateOperateBaseOperate', 'baseOperateAdminVo', baseOperateAdminVo)
            const localVarPath = `/__base/operate/updateOperate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOperateAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseOperateApi - functional programming interface
 * @export
 */
export const BaseOperateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseOperateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOperateBaseOperate(baseOperateAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除操作
         * @param {number} operateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delOperateBaseOperate(operateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delOperateBaseOperate(operateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户的操作列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOperatesBaseOperate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOperatesBaseOperate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject4} systemGenerateObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOperateBaseOperate(systemGenerateObject4: SystemGenerateObject4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOperateBaseOperate(systemGenerateObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject5} systemGenerateObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageOperateBaseOperate(systemGenerateObject5: SystemGenerateObject5, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageOperateBaseOperate(systemGenerateObject5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOperateBaseOperate(baseOperateAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseOperateApi - factory interface
 * @export
 */
export const BaseOperateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseOperateApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addOperateBaseOperate(baseOperateAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除操作
         * @param {number} operateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOperateBaseOperate(operateId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delOperateBaseOperate(operateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户的操作列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatesBaseOperate(options?: any): AxiosPromise<any> {
            return localVarFp.getOperatesBaseOperate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取操作列表
         * @param {SystemGenerateObject4} systemGenerateObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperateBaseOperate(systemGenerateObject4: SystemGenerateObject4, options?: any): AxiosPromise<any> {
            return localVarFp.listOperateBaseOperate(systemGenerateObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取操作列表(分页)
         * @param {SystemGenerateObject5} systemGenerateObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOperateBaseOperate(systemGenerateObject5: SystemGenerateObject5, options?: any): AxiosPromise<any> {
            return localVarFp.pageOperateBaseOperate(systemGenerateObject5, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改操作
         * @param {BaseOperateAdminVo} baseOperateAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateOperateBaseOperate(baseOperateAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseOperateApi - object-oriented interface
 * @export
 * @class BaseOperateApi
 * @extends {BaseAPI}
 */
export class BaseOperateApi extends BaseAPI {
    /**
     * 
     * @summary 添加操作
     * @param {BaseOperateAdminVo} baseOperateAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public addOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).addOperateBaseOperate(baseOperateAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除操作
     * @param {number} operateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public delOperateBaseOperate(operateId: number, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).delOperateBaseOperate(operateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户的操作列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public getOperatesBaseOperate(options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).getOperatesBaseOperate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取操作列表
     * @param {SystemGenerateObject4} systemGenerateObject4 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public listOperateBaseOperate(systemGenerateObject4: SystemGenerateObject4, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).listOperateBaseOperate(systemGenerateObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取操作列表(分页)
     * @param {SystemGenerateObject5} systemGenerateObject5 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public pageOperateBaseOperate(systemGenerateObject5: SystemGenerateObject5, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).pageOperateBaseOperate(systemGenerateObject5, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改操作
     * @param {BaseOperateAdminVo} baseOperateAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOperateApi
     */
    public updateOperateBaseOperate(baseOperateAdminVo: BaseOperateAdminVo, options?: AxiosRequestConfig) {
        return BaseOperateApiFp(this.configuration).updateOperateBaseOperate(baseOperateAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseOrganizationApi - axios parameter creator
 * @export
 */
export const BaseOrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationBaseOrganization: async (baseOrganizationAdminVo: BaseOrganizationAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseOrganizationAdminVo' is not null or undefined
            assertParamExists('addOrganizationBaseOrganization', 'baseOrganizationAdminVo', baseOrganizationAdminVo)
            const localVarPath = `/__base/organization/addOrganization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOrganizationAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除组织
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOrganizationBaseOrganization: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('delOrganizationBaseOrganization', 'organizationId', organizationId)
            const localVarPath = `/__base/organization/delOrganization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取组织列表
         * @param {SystemGenerateObject17} systemGenerateObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationsBaseOrganization: async (systemGenerateObject17: SystemGenerateObject17, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject17' is not null or undefined
            assertParamExists('listOrganizationsBaseOrganization', 'systemGenerateObject17', systemGenerateObject17)
            const localVarPath = `/__base/organization/listOrganizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject17, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取组织列表(分页)
         * @param {SystemGenerateObject18} systemGenerateObject18 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOrganizationsBaseOrganization: async (systemGenerateObject18: SystemGenerateObject18, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject18' is not null or undefined
            assertParamExists('pageOrganizationsBaseOrganization', 'systemGenerateObject18', systemGenerateObject18)
            const localVarPath = `/__base/organization/pageOrganizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject18, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        udpateOrganizationBaseOrganization: async (baseOrganizationAdminVo: BaseOrganizationAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseOrganizationAdminVo' is not null or undefined
            assertParamExists('udpateOrganizationBaseOrganization', 'baseOrganizationAdminVo', baseOrganizationAdminVo)
            const localVarPath = `/__base/organization/udpateOrganization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseOrganizationAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseOrganizationApi - functional programming interface
 * @export
 */
export const BaseOrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseOrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrganizationBaseOrganization(baseOrganizationAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除组织
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delOrganizationBaseOrganization(organizationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delOrganizationBaseOrganization(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取组织列表
         * @param {SystemGenerateObject17} systemGenerateObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrganizationsBaseOrganization(systemGenerateObject17: SystemGenerateObject17, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrganizationsBaseOrganization(systemGenerateObject17, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取组织列表(分页)
         * @param {SystemGenerateObject18} systemGenerateObject18 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageOrganizationsBaseOrganization(systemGenerateObject18: SystemGenerateObject18, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageOrganizationsBaseOrganization(systemGenerateObject18, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async udpateOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.udpateOrganizationBaseOrganization(baseOrganizationAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseOrganizationApi - factory interface
 * @export
 */
export const BaseOrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseOrganizationApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addOrganizationBaseOrganization(baseOrganizationAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除组织
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delOrganizationBaseOrganization(organizationId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delOrganizationBaseOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取组织列表
         * @param {SystemGenerateObject17} systemGenerateObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizationsBaseOrganization(systemGenerateObject17: SystemGenerateObject17, options?: any): AxiosPromise<any> {
            return localVarFp.listOrganizationsBaseOrganization(systemGenerateObject17, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取组织列表(分页)
         * @param {SystemGenerateObject18} systemGenerateObject18 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOrganizationsBaseOrganization(systemGenerateObject18: SystemGenerateObject18, options?: any): AxiosPromise<any> {
            return localVarFp.pageOrganizationsBaseOrganization(systemGenerateObject18, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改组织
         * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        udpateOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.udpateOrganizationBaseOrganization(baseOrganizationAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseOrganizationApi - object-oriented interface
 * @export
 * @class BaseOrganizationApi
 * @extends {BaseAPI}
 */
export class BaseOrganizationApi extends BaseAPI {
    /**
     * 
     * @summary 添加组织
     * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public addOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).addOrganizationBaseOrganization(baseOrganizationAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除组织
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public delOrganizationBaseOrganization(organizationId: number, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).delOrganizationBaseOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取组织列表
     * @param {SystemGenerateObject17} systemGenerateObject17 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public listOrganizationsBaseOrganization(systemGenerateObject17: SystemGenerateObject17, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).listOrganizationsBaseOrganization(systemGenerateObject17, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取组织列表(分页)
     * @param {SystemGenerateObject18} systemGenerateObject18 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public pageOrganizationsBaseOrganization(systemGenerateObject18: SystemGenerateObject18, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).pageOrganizationsBaseOrganization(systemGenerateObject18, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改组织
     * @param {BaseOrganizationAdminVo} baseOrganizationAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseOrganizationApi
     */
    public udpateOrganizationBaseOrganization(baseOrganizationAdminVo: BaseOrganizationAdminVo, options?: AxiosRequestConfig) {
        return BaseOrganizationApiFp(this.configuration).udpateOrganizationBaseOrganization(baseOrganizationAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseRouteApi - axios parameter creator
 * @export
 */
export const BaseRouteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRouteBaseRoute: async (baseRouteAdminVo: BaseRouteAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseRouteAdminVo' is not null or undefined
            assertParamExists('addRouteBaseRoute', 'baseRouteAdminVo', baseRouteAdminVo)
            const localVarPath = `/__base/route/addRoute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRouteAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除路由
         * @param {number} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoteBaseRoute: async (routeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeId' is not null or undefined
            assertParamExists('deleteRoteBaseRoute', 'routeId', routeId)
            const localVarPath = `/__base/route/deleteRote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (routeId !== undefined) {
                localVarQueryParameter['routeId'] = routeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取无需授权的动态路由
         * @summary 获取无需授权的动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoPermissionRoutesBaseRoute: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/route/getNoPermissionRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取动态路由
         * @summary 获取动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionRoutesBaseRoute: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/route/getPermissionRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取路由列表
         * @param {SystemGenerateObject6} systemGenerateObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoutesBaseRoute: async (systemGenerateObject6: SystemGenerateObject6, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject6' is not null or undefined
            assertParamExists('listRoutesBaseRoute', 'systemGenerateObject6', systemGenerateObject6)
            const localVarPath = `/__base/route/listRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject6, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取路由列表(分页)
         * @param {SystemGenerateObject7} systemGenerateObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRoutesBaseRoute: async (systemGenerateObject7: SystemGenerateObject7, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject7' is not null or undefined
            assertParamExists('pageRoutesBaseRoute', 'systemGenerateObject7', systemGenerateObject7)
            const localVarPath = `/__base/route/pageRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject7, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRouteBaseRoute: async (baseRouteAdminVo: BaseRouteAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseRouteAdminVo' is not null or undefined
            assertParamExists('updateRouteBaseRoute', 'baseRouteAdminVo', baseRouteAdminVo)
            const localVarPath = `/__base/route/updateRoute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRouteAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseRouteApi - functional programming interface
 * @export
 */
export const BaseRouteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseRouteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRouteBaseRoute(baseRouteAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除路由
         * @param {number} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoteBaseRoute(routeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoteBaseRoute(routeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取无需授权的动态路由
         * @summary 获取无需授权的动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoPermissionRoutesBaseRoute(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoPermissionRoutesBaseRoute(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取动态路由
         * @summary 获取动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionRoutesBaseRoute(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissionRoutesBaseRoute(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取路由列表
         * @param {SystemGenerateObject6} systemGenerateObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRoutesBaseRoute(systemGenerateObject6: SystemGenerateObject6, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRoutesBaseRoute(systemGenerateObject6, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取路由列表(分页)
         * @param {SystemGenerateObject7} systemGenerateObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageRoutesBaseRoute(systemGenerateObject7: SystemGenerateObject7, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageRoutesBaseRoute(systemGenerateObject7, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRouteBaseRoute(baseRouteAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseRouteApi - factory interface
 * @export
 */
export const BaseRouteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseRouteApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addRouteBaseRoute(baseRouteAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除路由
         * @param {number} routeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoteBaseRoute(routeId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.deleteRoteBaseRoute(routeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取无需授权的动态路由
         * @summary 获取无需授权的动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoPermissionRoutesBaseRoute(options?: any): AxiosPromise<any> {
            return localVarFp.getNoPermissionRoutesBaseRoute(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取动态路由
         * @summary 获取动态路由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionRoutesBaseRoute(options?: any): AxiosPromise<any> {
            return localVarFp.getPermissionRoutesBaseRoute(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取路由列表
         * @param {SystemGenerateObject6} systemGenerateObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoutesBaseRoute(systemGenerateObject6: SystemGenerateObject6, options?: any): AxiosPromise<any> {
            return localVarFp.listRoutesBaseRoute(systemGenerateObject6, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取路由列表(分页)
         * @param {SystemGenerateObject7} systemGenerateObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRoutesBaseRoute(systemGenerateObject7: SystemGenerateObject7, options?: any): AxiosPromise<any> {
            return localVarFp.pageRoutesBaseRoute(systemGenerateObject7, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改路由
         * @param {BaseRouteAdminVo} baseRouteAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateRouteBaseRoute(baseRouteAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseRouteApi - object-oriented interface
 * @export
 * @class BaseRouteApi
 * @extends {BaseAPI}
 */
export class BaseRouteApi extends BaseAPI {
    /**
     * 
     * @summary 添加路由
     * @param {BaseRouteAdminVo} baseRouteAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public addRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).addRouteBaseRoute(baseRouteAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除路由
     * @param {number} routeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public deleteRoteBaseRoute(routeId: number, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).deleteRoteBaseRoute(routeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取无需授权的动态路由
     * @summary 获取无需授权的动态路由
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public getNoPermissionRoutesBaseRoute(options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).getNoPermissionRoutesBaseRoute(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取动态路由
     * @summary 获取动态路由
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public getPermissionRoutesBaseRoute(options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).getPermissionRoutesBaseRoute(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取路由列表
     * @param {SystemGenerateObject6} systemGenerateObject6 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public listRoutesBaseRoute(systemGenerateObject6: SystemGenerateObject6, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).listRoutesBaseRoute(systemGenerateObject6, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取路由列表(分页)
     * @param {SystemGenerateObject7} systemGenerateObject7 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public pageRoutesBaseRoute(systemGenerateObject7: SystemGenerateObject7, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).pageRoutesBaseRoute(systemGenerateObject7, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改路由
     * @param {BaseRouteAdminVo} baseRouteAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseRouteApi
     */
    public updateRouteBaseRoute(baseRouteAdminVo: BaseRouteAdminVo, options?: AxiosRequestConfig) {
        return BaseRouteApiFp(this.configuration).updateRouteBaseRoute(baseRouteAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseScheduleApi - axios parameter creator
 * @export
 */
export const BaseScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 删除任务
         * @param {string} scheduleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduleBaseSchedule: async (scheduleName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleName' is not null or undefined
            assertParamExists('deleteScheduleBaseSchedule', 'scheduleName', scheduleName)
            const localVarPath = `/__base/schedule/deleteSchedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (scheduleName !== undefined) {
                localVarQueryParameter['scheduleName'] = scheduleName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取任务列表(分页)
         * @param {SystemGenerateObject8} systemGenerateObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSchedulesBaseSchedule: async (systemGenerateObject8: SystemGenerateObject8, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject8' is not null or undefined
            assertParamExists('pageSchedulesBaseSchedule', 'systemGenerateObject8', systemGenerateObject8)
            const localVarPath = `/__base/schedule/pageSchedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject8, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseScheduleApi - functional programming interface
 * @export
 */
export const BaseScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 删除任务
         * @param {string} scheduleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScheduleBaseSchedule(scheduleName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScheduleBaseSchedule(scheduleName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取任务列表(分页)
         * @param {SystemGenerateObject8} systemGenerateObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageSchedulesBaseSchedule(systemGenerateObject8: SystemGenerateObject8, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageSchedulesBaseSchedule(systemGenerateObject8, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseScheduleApi - factory interface
 * @export
 */
export const BaseScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseScheduleApiFp(configuration)
    return {
        /**
         * 
         * @summary 删除任务
         * @param {string} scheduleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduleBaseSchedule(scheduleName: string, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.deleteScheduleBaseSchedule(scheduleName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取任务列表(分页)
         * @param {SystemGenerateObject8} systemGenerateObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSchedulesBaseSchedule(systemGenerateObject8: SystemGenerateObject8, options?: any): AxiosPromise<any> {
            return localVarFp.pageSchedulesBaseSchedule(systemGenerateObject8, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseScheduleApi - object-oriented interface
 * @export
 * @class BaseScheduleApi
 * @extends {BaseAPI}
 */
export class BaseScheduleApi extends BaseAPI {
    /**
     * 
     * @summary 删除任务
     * @param {string} scheduleName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseScheduleApi
     */
    public deleteScheduleBaseSchedule(scheduleName: string, options?: AxiosRequestConfig) {
        return BaseScheduleApiFp(this.configuration).deleteScheduleBaseSchedule(scheduleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取任务列表(分页)
     * @param {SystemGenerateObject8} systemGenerateObject8 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseScheduleApi
     */
    public pageSchedulesBaseSchedule(systemGenerateObject8: SystemGenerateObject8, options?: AxiosRequestConfig) {
        return BaseScheduleApiFp(this.configuration).pageSchedulesBaseSchedule(systemGenerateObject8, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseUserApi - axios parameter creator
 * @export
 */
export const BaseUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleBaseUser: async (baseRoleAdminVo: BaseRoleAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseRoleAdminVo' is not null or undefined
            assertParamExists('addRoleBaseUser', 'baseRoleAdminVo', baseRoleAdminVo)
            const localVarPath = `/__base/user/addRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRoleAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserBaseUser: async (baseUserAdminVo: BaseUserAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUserAdminVo' is not null or undefined
            assertParamExists('addUserBaseUser', 'baseUserAdminVo', baseUserAdminVo)
            const localVarPath = `/__base/user/addUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseUserAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除角色
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delRoleBaseUser: async (roleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('delRoleBaseUser', 'roleId', roleId)
            const localVarPath = `/__base/user/delRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除用户
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserBaseUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserBaseUser', 'userId', userId)
            const localVarPath = `/__base/user/deleteUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取组织机构下角色列表
         * @param {string} orgCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgRolesBaseUser: async (orgCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgCode' is not null or undefined
            assertParamExists('listOrgRolesBaseUser', 'orgCode', orgCode)
            const localVarPath = `/__base/user/listOrgRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgCode !== undefined) {
                localVarQueryParameter['orgCode'] = orgCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取角色列表
         * @param {SystemGenerateObject12} systemGenerateObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRolesBaseUser: async (systemGenerateObject12: SystemGenerateObject12, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject12' is not null or undefined
            assertParamExists('listRolesBaseUser', 'systemGenerateObject12', systemGenerateObject12)
            const localVarPath = `/__base/user/listRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject12, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取系统角色列表
         * @param {SystemGenerateObject10} systemGenerateObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSystemRolesBaseUser: async (systemGenerateObject10: SystemGenerateObject10, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject10' is not null or undefined
            assertParamExists('listSystemRolesBaseUser', 'systemGenerateObject10', systemGenerateObject10)
            const localVarPath = `/__base/user/listSystemRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject10, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询用户的组织角色列表
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserOrgRolesBaseUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listUserOrgRolesBaseUser', 'userId', userId)
            const localVarPath = `/__base/user/listUserOrgRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户列表
         * @param {SystemGenerateObject14} systemGenerateObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersBaseUser: async (systemGenerateObject14: SystemGenerateObject14, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject14' is not null or undefined
            assertParamExists('listUsersBaseUser', 'systemGenerateObject14', systemGenerateObject14)
            const localVarPath = `/__base/user/listUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject14, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 登录
         * @summary 登录
         * @param {string} userNameOrPhone 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginBaseUser: async (userNameOrPhone: string, password: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userNameOrPhone' is not null or undefined
            assertParamExists('loginBaseUser', 'userNameOrPhone', userNameOrPhone)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginBaseUser', 'password', password)
            const localVarPath = `/__base/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (userNameOrPhone !== undefined) { 
                localVarFormParams.set('userNameOrPhone', userNameOrPhone as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 登出
         * @summary 登出
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutBaseUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/user/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取角色列表(分页)
         * @param {SystemGenerateObject13} systemGenerateObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRolesBaseUser: async (systemGenerateObject13: SystemGenerateObject13, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject13' is not null or undefined
            assertParamExists('pageRolesBaseUser', 'systemGenerateObject13', systemGenerateObject13)
            const localVarPath = `/__base/user/pageRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject13, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户列表(分页)
         * @param {SystemGenerateObject15} systemGenerateObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageUsersBaseUser: async (systemGenerateObject15: SystemGenerateObject15, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject15' is not null or undefined
            assertParamExists('pageUsersBaseUser', 'systemGenerateObject15', systemGenerateObject15)
            const localVarPath = `/__base/user/pageUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject15, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        udpateRoleBaseUser: async (baseRoleAdminVo: BaseRoleAdminVo, force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseRoleAdminVo' is not null or undefined
            assertParamExists('udpateRoleBaseUser', 'baseRoleAdminVo', baseRoleAdminVo)
            const localVarPath = `/__base/user/udpateRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRoleAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserBaseUser: async (baseUserAdminVo: BaseUserAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUserAdminVo' is not null or undefined
            assertParamExists('updateUserBaseUser', 'baseUserAdminVo', baseUserAdminVo)
            const localVarPath = `/__base/user/updateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseUserAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新用户的组织角色列表
         * @param {SystemGenerateObject11} systemGenerateObject11 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserOrgRolesBaseUser: async (systemGenerateObject11: SystemGenerateObject11, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject11' is not null or undefined
            assertParamExists('updateUserOrgRolesBaseUser', 'systemGenerateObject11', systemGenerateObject11)
            const localVarPath = `/__base/user/updateUserOrgRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject11, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改用户密码
         * @param {SystemGenerateObject16} systemGenerateObject16 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPWBaseUser: async (systemGenerateObject16: SystemGenerateObject16, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject16' is not null or undefined
            assertParamExists('updateUserPWBaseUser', 'systemGenerateObject16', systemGenerateObject16)
            const localVarPath = `/__base/user/updateUserPW`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject16, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseUserApi - functional programming interface
 * @export
 */
export const BaseUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRoleBaseUser(baseRoleAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserBaseUser(baseUserAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除角色
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delRoleBaseUser(roleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delRoleBaseUser(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除用户
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserBaseUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserBaseUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取组织机构下角色列表
         * @param {string} orgCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrgRolesBaseUser(orgCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrgRolesBaseUser(orgCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取角色列表
         * @param {SystemGenerateObject12} systemGenerateObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRolesBaseUser(systemGenerateObject12: SystemGenerateObject12, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRolesBaseUser(systemGenerateObject12, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取系统角色列表
         * @param {SystemGenerateObject10} systemGenerateObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSystemRolesBaseUser(systemGenerateObject10: SystemGenerateObject10, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSystemRolesBaseUser(systemGenerateObject10, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询用户的组织角色列表
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserOrgRolesBaseUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserOrgRolesBaseUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户列表
         * @param {SystemGenerateObject14} systemGenerateObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsersBaseUser(systemGenerateObject14: SystemGenerateObject14, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsersBaseUser(systemGenerateObject14, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 登录
         * @summary 登录
         * @param {string} userNameOrPhone 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginBaseUser(userNameOrPhone: string, password: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginBaseUser(userNameOrPhone, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 登出
         * @summary 登出
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutBaseUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutBaseUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取角色列表(分页)
         * @param {SystemGenerateObject13} systemGenerateObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageRolesBaseUser(systemGenerateObject13: SystemGenerateObject13, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageRolesBaseUser(systemGenerateObject13, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户列表(分页)
         * @param {SystemGenerateObject15} systemGenerateObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageUsersBaseUser(systemGenerateObject15: SystemGenerateObject15, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageUsersBaseUser(systemGenerateObject15, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async udpateRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, force?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.udpateRoleBaseUser(baseRoleAdminVo, force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserBaseUser(baseUserAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新用户的组织角色列表
         * @param {SystemGenerateObject11} systemGenerateObject11 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserOrgRolesBaseUser(systemGenerateObject11: SystemGenerateObject11, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserOrgRolesBaseUser(systemGenerateObject11, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改用户密码
         * @param {SystemGenerateObject16} systemGenerateObject16 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPWBaseUser(systemGenerateObject16: SystemGenerateObject16, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPWBaseUser(systemGenerateObject16, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseUserApi - factory interface
 * @export
 */
export const BaseUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseUserApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addRoleBaseUser(baseRoleAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.addUserBaseUser(baseUserAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除角色
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delRoleBaseUser(roleId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.delRoleBaseUser(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除用户
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserBaseUser(userId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.deleteUserBaseUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取组织机构下角色列表
         * @param {string} orgCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgRolesBaseUser(orgCode: string, options?: any): AxiosPromise<any> {
            return localVarFp.listOrgRolesBaseUser(orgCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取角色列表
         * @param {SystemGenerateObject12} systemGenerateObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRolesBaseUser(systemGenerateObject12: SystemGenerateObject12, options?: any): AxiosPromise<any> {
            return localVarFp.listRolesBaseUser(systemGenerateObject12, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取系统角色列表
         * @param {SystemGenerateObject10} systemGenerateObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSystemRolesBaseUser(systemGenerateObject10: SystemGenerateObject10, options?: any): AxiosPromise<any> {
            return localVarFp.listSystemRolesBaseUser(systemGenerateObject10, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询用户的组织角色列表
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserOrgRolesBaseUser(userId: number, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.listUserOrgRolesBaseUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户列表
         * @param {SystemGenerateObject14} systemGenerateObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersBaseUser(systemGenerateObject14: SystemGenerateObject14, options?: any): AxiosPromise<any> {
            return localVarFp.listUsersBaseUser(systemGenerateObject14, options).then((request) => request(axios, basePath));
        },
        /**
         * 登录
         * @summary 登录
         * @param {string} userNameOrPhone 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginBaseUser(userNameOrPhone: string, password: string, options?: any): AxiosPromise<any> {
            return localVarFp.loginBaseUser(userNameOrPhone, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 登出
         * @summary 登出
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutBaseUser(options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.logoutBaseUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取角色列表(分页)
         * @param {SystemGenerateObject13} systemGenerateObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRolesBaseUser(systemGenerateObject13: SystemGenerateObject13, options?: any): AxiosPromise<any> {
            return localVarFp.pageRolesBaseUser(systemGenerateObject13, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户列表(分页)
         * @param {SystemGenerateObject15} systemGenerateObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageUsersBaseUser(systemGenerateObject15: SystemGenerateObject15, options?: any): AxiosPromise<any> {
            return localVarFp.pageUsersBaseUser(systemGenerateObject15, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改角色
         * @param {BaseRoleAdminVo} baseRoleAdminVo 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        udpateRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, force?: boolean, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.udpateRoleBaseUser(baseRoleAdminVo, force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改用户
         * @param {BaseUserAdminVo} baseUserAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateUserBaseUser(baseUserAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新用户的组织角色列表
         * @param {SystemGenerateObject11} systemGenerateObject11 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserOrgRolesBaseUser(systemGenerateObject11: SystemGenerateObject11, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateUserOrgRolesBaseUser(systemGenerateObject11, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改用户密码
         * @param {SystemGenerateObject16} systemGenerateObject16 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPWBaseUser(systemGenerateObject16: SystemGenerateObject16, options?: any): AxiosPromise<BaseResultVo> {
            return localVarFp.updateUserPWBaseUser(systemGenerateObject16, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseUserApi - object-oriented interface
 * @export
 * @class BaseUserApi
 * @extends {BaseAPI}
 */
export class BaseUserApi extends BaseAPI {
    /**
     * 
     * @summary 添加角色
     * @param {BaseRoleAdminVo} baseRoleAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public addRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).addRoleBaseUser(baseRoleAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加用户
     * @param {BaseUserAdminVo} baseUserAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public addUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).addUserBaseUser(baseUserAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除角色
     * @param {number} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public delRoleBaseUser(roleId: number, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).delRoleBaseUser(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除用户
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public deleteUserBaseUser(userId: number, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).deleteUserBaseUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取组织机构下角色列表
     * @param {string} orgCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listOrgRolesBaseUser(orgCode: string, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listOrgRolesBaseUser(orgCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取角色列表
     * @param {SystemGenerateObject12} systemGenerateObject12 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listRolesBaseUser(systemGenerateObject12: SystemGenerateObject12, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listRolesBaseUser(systemGenerateObject12, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取系统角色列表
     * @param {SystemGenerateObject10} systemGenerateObject10 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listSystemRolesBaseUser(systemGenerateObject10: SystemGenerateObject10, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listSystemRolesBaseUser(systemGenerateObject10, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询用户的组织角色列表
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listUserOrgRolesBaseUser(userId: number, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listUserOrgRolesBaseUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户列表
     * @param {SystemGenerateObject14} systemGenerateObject14 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public listUsersBaseUser(systemGenerateObject14: SystemGenerateObject14, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).listUsersBaseUser(systemGenerateObject14, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 登录
     * @summary 登录
     * @param {string} userNameOrPhone 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public loginBaseUser(userNameOrPhone: string, password: string, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).loginBaseUser(userNameOrPhone, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 登出
     * @summary 登出
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public logoutBaseUser(options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).logoutBaseUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取角色列表(分页)
     * @param {SystemGenerateObject13} systemGenerateObject13 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public pageRolesBaseUser(systemGenerateObject13: SystemGenerateObject13, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).pageRolesBaseUser(systemGenerateObject13, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户列表(分页)
     * @param {SystemGenerateObject15} systemGenerateObject15 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public pageUsersBaseUser(systemGenerateObject15: SystemGenerateObject15, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).pageUsersBaseUser(systemGenerateObject15, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改角色
     * @param {BaseRoleAdminVo} baseRoleAdminVo 
     * @param {boolean} [force] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public udpateRoleBaseUser(baseRoleAdminVo: BaseRoleAdminVo, force?: boolean, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).udpateRoleBaseUser(baseRoleAdminVo, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改用户
     * @param {BaseUserAdminVo} baseUserAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public updateUserBaseUser(baseUserAdminVo: BaseUserAdminVo, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).updateUserBaseUser(baseUserAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新用户的组织角色列表
     * @param {SystemGenerateObject11} systemGenerateObject11 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public updateUserOrgRolesBaseUser(systemGenerateObject11: SystemGenerateObject11, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).updateUserOrgRolesBaseUser(systemGenerateObject11, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改用户密码
     * @param {SystemGenerateObject16} systemGenerateObject16 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUserApi
     */
    public updateUserPWBaseUser(systemGenerateObject16: SystemGenerateObject16, options?: AxiosRequestConfig) {
        return BaseUserApiFp(this.configuration).updateUserPWBaseUser(systemGenerateObject16, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BaseUtilApi - axios parameter creator
 * @export
 */
export const BaseUtilApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取id
         * @summary 获取id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdBaseUtil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/util/getId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取id
         * @summary 获取ids
         * @param {number} count 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdsBaseUtil: async (count: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'count' is not null or undefined
            assertParamExists('getIdsBaseUtil', 'count', count)
            const localVarPath = `/__base/util/getIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取uuid
         * @summary 获取uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUUIDBaseUtil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/__base/util/getUUID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseUtilApi - functional programming interface
 * @export
 */
export const BaseUtilApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseUtilApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取id
         * @summary 获取id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdBaseUtil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdBaseUtil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取id
         * @summary 获取ids
         * @param {number} count 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdsBaseUtil(count: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdsBaseUtil(count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取uuid
         * @summary 获取uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUUIDBaseUtil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUUIDBaseUtil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseUtilApi - factory interface
 * @export
 */
export const BaseUtilApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseUtilApiFp(configuration)
    return {
        /**
         * 获取id
         * @summary 获取id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdBaseUtil(options?: any): AxiosPromise<number> {
            return localVarFp.getIdBaseUtil(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取id
         * @summary 获取ids
         * @param {number} count 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdsBaseUtil(count: number, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getIdsBaseUtil(count, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取uuid
         * @summary 获取uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUUIDBaseUtil(options?: any): AxiosPromise<string> {
            return localVarFp.getUUIDBaseUtil(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseUtilApi - object-oriented interface
 * @export
 * @class BaseUtilApi
 * @extends {BaseAPI}
 */
export class BaseUtilApi extends BaseAPI {
    /**
     * 获取id
     * @summary 获取id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUtilApi
     */
    public getIdBaseUtil(options?: AxiosRequestConfig) {
        return BaseUtilApiFp(this.configuration).getIdBaseUtil(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取id
     * @summary 获取ids
     * @param {number} count 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUtilApi
     */
    public getIdsBaseUtil(count: number, options?: AxiosRequestConfig) {
        return BaseUtilApiFp(this.configuration).getIdsBaseUtil(count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取uuid
     * @summary 获取uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseUtilApi
     */
    public getUUIDBaseUtil(options?: AxiosRequestConfig) {
        return BaseUtilApiFp(this.configuration).getUUIDBaseUtil(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BillApi - axios parameter creator
 * @export
 */
export const BillApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 主动退款
         * @param {SystemGenerateObject51} systemGenerateObject51 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backBillWxBill: async (systemGenerateObject51: SystemGenerateObject51, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject51' is not null or undefined
            assertParamExists('backBillWxBill', 'systemGenerateObject51', systemGenerateObject51)
            const localVarPath = `/bill/backBillWx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject51, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取财务账单列表
         * @param {SystemGenerateObject49} systemGenerateObject49 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBillBill: async (systemGenerateObject49: SystemGenerateObject49, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject49' is not null or undefined
            assertParamExists('listBillBill', 'systemGenerateObject49', systemGenerateObject49)
            const localVarPath = `/bill/listBill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject49, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取财务账单列表(分页)
         * @param {SystemGenerateObject50} systemGenerateObject50 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageBillBill: async (systemGenerateObject50: SystemGenerateObject50, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject50' is not null or undefined
            assertParamExists('pageBillBill', 'systemGenerateObject50', systemGenerateObject50)
            const localVarPath = `/bill/pageBill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject50, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取客户端当前账单的情况
         * @param {number} billId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryClientBillStatusBill: async (billId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billId' is not null or undefined
            assertParamExists('queryClientBillStatusBill', 'billId', billId)
            const localVarPath = `/bill/queryClientBillStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (billId !== undefined) {
                localVarQueryParameter['billId'] = billId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 统计财务账单
         * @param {SystemGenerateObject48} systemGenerateObject48 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticBillBill: async (systemGenerateObject48: SystemGenerateObject48, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject48' is not null or undefined
            assertParamExists('statisticBillBill', 'systemGenerateObject48', systemGenerateObject48)
            const localVarPath = `/bill/statisticBill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject48, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillApi - functional programming interface
 * @export
 */
export const BillApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 主动退款
         * @param {SystemGenerateObject51} systemGenerateObject51 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backBillWxBill(systemGenerateObject51: SystemGenerateObject51, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backBillWxBill(systemGenerateObject51, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取财务账单列表
         * @param {SystemGenerateObject49} systemGenerateObject49 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBillBill(systemGenerateObject49: SystemGenerateObject49, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBillBill(systemGenerateObject49, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取财务账单列表(分页)
         * @param {SystemGenerateObject50} systemGenerateObject50 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageBillBill(systemGenerateObject50: SystemGenerateObject50, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageBillBill(systemGenerateObject50, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取客户端当前账单的情况
         * @param {number} billId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryClientBillStatusBill(billId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryClientBillStatusBill(billId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 统计财务账单
         * @param {SystemGenerateObject48} systemGenerateObject48 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticBillBill(systemGenerateObject48: SystemGenerateObject48, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticBillBill(systemGenerateObject48, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillApi - factory interface
 * @export
 */
export const BillApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillApiFp(configuration)
    return {
        /**
         * 
         * @summary 主动退款
         * @param {SystemGenerateObject51} systemGenerateObject51 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backBillWxBill(systemGenerateObject51: SystemGenerateObject51, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.backBillWxBill(systemGenerateObject51, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取财务账单列表
         * @param {SystemGenerateObject49} systemGenerateObject49 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBillBill(systemGenerateObject49: SystemGenerateObject49, options?: any): AxiosPromise<any> {
            return localVarFp.listBillBill(systemGenerateObject49, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取财务账单列表(分页)
         * @param {SystemGenerateObject50} systemGenerateObject50 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageBillBill(systemGenerateObject50: SystemGenerateObject50, options?: any): AxiosPromise<any> {
            return localVarFp.pageBillBill(systemGenerateObject50, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取客户端当前账单的情况
         * @param {number} billId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryClientBillStatusBill(billId: number, options?: any): AxiosPromise<any> {
            return localVarFp.queryClientBillStatusBill(billId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 统计财务账单
         * @param {SystemGenerateObject48} systemGenerateObject48 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticBillBill(systemGenerateObject48: SystemGenerateObject48, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.statisticBillBill(systemGenerateObject48, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillApi - object-oriented interface
 * @export
 * @class BillApi
 * @extends {BaseAPI}
 */
export class BillApi extends BaseAPI {
    /**
     * 
     * @summary 主动退款
     * @param {SystemGenerateObject51} systemGenerateObject51 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillApi
     */
    public backBillWxBill(systemGenerateObject51: SystemGenerateObject51, options?: AxiosRequestConfig) {
        return BillApiFp(this.configuration).backBillWxBill(systemGenerateObject51, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取财务账单列表
     * @param {SystemGenerateObject49} systemGenerateObject49 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillApi
     */
    public listBillBill(systemGenerateObject49: SystemGenerateObject49, options?: AxiosRequestConfig) {
        return BillApiFp(this.configuration).listBillBill(systemGenerateObject49, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取财务账单列表(分页)
     * @param {SystemGenerateObject50} systemGenerateObject50 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillApi
     */
    public pageBillBill(systemGenerateObject50: SystemGenerateObject50, options?: AxiosRequestConfig) {
        return BillApiFp(this.configuration).pageBillBill(systemGenerateObject50, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取客户端当前账单的情况
     * @param {number} billId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillApi
     */
    public queryClientBillStatusBill(billId: number, options?: AxiosRequestConfig) {
        return BillApiFp(this.configuration).queryClientBillStatusBill(billId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 统计财务账单
     * @param {SystemGenerateObject48} systemGenerateObject48 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillApi
     */
    public statisticBillBill(systemGenerateObject48: SystemGenerateObject48, options?: AxiosRequestConfig) {
        return BillApiFp(this.configuration).statisticBillBill(systemGenerateObject48, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加设备
         * @param {DeviceAdminVo} deviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceDevice: async (deviceAdminVo: DeviceAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceAdminVo' is not null or undefined
            assertParamExists('addDeviceDevice', 'deviceAdminVo', deviceAdminVo)
            const localVarPath = `/device/addDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除设备
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deleteDeviceDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deleteDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除定时设置设备的模式
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimerDeviceSetModDevice: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteTimerDeviceSetModDevice', 'name', name)
            const localVarPath = `/device/deleteTimerDeviceSetMod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理员开门
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceAdminOpendoorDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceAdminOpendoorDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deviceAdminOpendoor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备模式
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGetModDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceGetModDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deviceGetMod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 设置设备的模式
         * @param {number} deviceId 
         * @param {DEVICEMODE} mod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSetModDevice: async (deviceId: number, mod: DEVICEMODE, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceSetModDevice', 'deviceId', deviceId)
            // verify required parameter 'mod' is not null or undefined
            assertParamExists('deviceSetModDevice', 'mod', mod)
            const localVarPath = `/device/deviceSetMod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (mod !== undefined) {
                localVarQueryParameter['mod'] = mod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备状态
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceStatusDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceStatusDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deviceStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 设备升级
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceUpgradeDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceUpgradeDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deviceUpgrade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备版本
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceVersionDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceVersionDevice', 'deviceId', deviceId)
            const localVarPath = `/device/deviceVersion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备列表
         * @param {SystemGenerateObject22} systemGenerateObject22 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceDevice: async (systemGenerateObject22: SystemGenerateObject22, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject22' is not null or undefined
            assertParamExists('listDeviceDevice', 'systemGenerateObject22', systemGenerateObject22)
            const localVarPath = `/device/listDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject22, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备类型列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceTypeDevice: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/device/listDeviceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取定时设置设备的模式列表
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTimerDeviceSetModDevice: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('listTimerDeviceSetModDevice', 'deviceId', deviceId)
            const localVarPath = `/device/listTimerDeviceSetMod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备列表(分页)
         * @param {SystemGenerateObject23} systemGenerateObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDeviceDevice: async (systemGenerateObject23: SystemGenerateObject23, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject23' is not null or undefined
            assertParamExists('pageDeviceDevice', 'systemGenerateObject23', systemGenerateObject23)
            const localVarPath = `/device/pageDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject23, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 定时设置设备的模式
         * @param {SystemGenerateObject24} systemGenerateObject24 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timerDeviceSetModDevice: async (systemGenerateObject24: SystemGenerateObject24, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject24' is not null or undefined
            assertParamExists('timerDeviceSetModDevice', 'systemGenerateObject24', systemGenerateObject24)
            const localVarPath = `/device/timerDeviceSetMod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject24, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新设备
         * @param {DeviceAdminVo} deviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceDevice: async (deviceAdminVo: DeviceAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceAdminVo' is not null or undefined
            assertParamExists('updateDeviceDevice', 'deviceAdminVo', deviceAdminVo)
            const localVarPath = `/device/updateDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加设备
         * @param {DeviceAdminVo} deviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDeviceDevice(deviceAdminVo: DeviceAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDeviceDevice(deviceAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除设备
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeviceDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeviceDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除定时设置设备的模式
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTimerDeviceSetModDevice(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTimerDeviceSetModDevice(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理员开门
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceAdminOpendoorDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceAdminOpendoorDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备模式
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceGetModDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceGetModDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 设置设备的模式
         * @param {number} deviceId 
         * @param {DEVICEMODE} mod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSetModDevice(deviceId: number, mod: DEVICEMODE, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceSetModDevice(deviceId, mod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备状态
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceStatusDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceStatusDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 设备升级
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceUpgradeDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceUpgradeDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备版本
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceVersionDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceVersionDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备列表
         * @param {SystemGenerateObject22} systemGenerateObject22 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDeviceDevice(systemGenerateObject22: SystemGenerateObject22, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDeviceDevice(systemGenerateObject22, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备类型列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDeviceTypeDevice(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDeviceTypeDevice(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取定时设置设备的模式列表
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTimerDeviceSetModDevice(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTimerDeviceSetModDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备列表(分页)
         * @param {SystemGenerateObject23} systemGenerateObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageDeviceDevice(systemGenerateObject23: SystemGenerateObject23, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageDeviceDevice(systemGenerateObject23, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 定时设置设备的模式
         * @param {SystemGenerateObject24} systemGenerateObject24 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timerDeviceSetModDevice(systemGenerateObject24: SystemGenerateObject24, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timerDeviceSetModDevice(systemGenerateObject24, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新设备
         * @param {DeviceAdminVo} deviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeviceDevice(deviceAdminVo: DeviceAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeviceDevice(deviceAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加设备
         * @param {DeviceAdminVo} deviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceDevice(deviceAdminVo: DeviceAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addDeviceDevice(deviceAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除设备
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceDevice(deviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deleteDeviceDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除定时设置设备的模式
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimerDeviceSetModDevice(name: string, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deleteTimerDeviceSetModDevice(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理员开门
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceAdminOpendoorDevice(deviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deviceAdminOpendoorDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备模式
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGetModDevice(deviceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deviceGetModDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 设置设备的模式
         * @param {number} deviceId 
         * @param {DEVICEMODE} mod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSetModDevice(deviceId: number, mod: DEVICEMODE, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deviceSetModDevice(deviceId, mod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备状态
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceStatusDevice(deviceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deviceStatusDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 设备升级
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceUpgradeDevice(deviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deviceUpgradeDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备版本
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceVersionDevice(deviceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deviceVersionDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备列表
         * @param {SystemGenerateObject22} systemGenerateObject22 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceDevice(systemGenerateObject22: SystemGenerateObject22, options?: any): AxiosPromise<any> {
            return localVarFp.listDeviceDevice(systemGenerateObject22, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备类型列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceTypeDevice(options?: any): AxiosPromise<any> {
            return localVarFp.listDeviceTypeDevice(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取定时设置设备的模式列表
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTimerDeviceSetModDevice(deviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.listTimerDeviceSetModDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备列表(分页)
         * @param {SystemGenerateObject23} systemGenerateObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDeviceDevice(systemGenerateObject23: SystemGenerateObject23, options?: any): AxiosPromise<any> {
            return localVarFp.pageDeviceDevice(systemGenerateObject23, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 定时设置设备的模式
         * @param {SystemGenerateObject24} systemGenerateObject24 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timerDeviceSetModDevice(systemGenerateObject24: SystemGenerateObject24, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.timerDeviceSetModDevice(systemGenerateObject24, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新设备
         * @param {DeviceAdminVo} deviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceDevice(deviceAdminVo: DeviceAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateDeviceDevice(deviceAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI {
    /**
     * 
     * @summary 添加设备
     * @param {DeviceAdminVo} deviceAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public addDeviceDevice(deviceAdminVo: DeviceAdminVo, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).addDeviceDevice(deviceAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除设备
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deleteDeviceDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deleteDeviceDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除定时设置设备的模式
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deleteTimerDeviceSetModDevice(name: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deleteTimerDeviceSetModDevice(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理员开门
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceAdminOpendoorDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceAdminOpendoorDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备模式
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceGetModDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceGetModDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 设置设备的模式
     * @param {number} deviceId 
     * @param {DEVICEMODE} mod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceSetModDevice(deviceId: number, mod: DEVICEMODE, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceSetModDevice(deviceId, mod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备状态
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceStatusDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceStatusDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 设备升级
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceUpgradeDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceUpgradeDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备版本
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceVersionDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceVersionDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备列表
     * @param {SystemGenerateObject22} systemGenerateObject22 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public listDeviceDevice(systemGenerateObject22: SystemGenerateObject22, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).listDeviceDevice(systemGenerateObject22, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备类型列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public listDeviceTypeDevice(options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).listDeviceTypeDevice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取定时设置设备的模式列表
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public listTimerDeviceSetModDevice(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).listTimerDeviceSetModDevice(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备列表(分页)
     * @param {SystemGenerateObject23} systemGenerateObject23 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public pageDeviceDevice(systemGenerateObject23: SystemGenerateObject23, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).pageDeviceDevice(systemGenerateObject23, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 定时设置设备的模式
     * @param {SystemGenerateObject24} systemGenerateObject24 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public timerDeviceSetModDevice(systemGenerateObject24: SystemGenerateObject24, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).timerDeviceSetModDevice(systemGenerateObject24, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新设备
     * @param {DeviceAdminVo} deviceAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public updateDeviceDevice(deviceAdminVo: DeviceAdminVo, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).updateDeviceDevice(deviceAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DevicelinkApi - axios parameter creator
 * @export
 */
export const DevicelinkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加设备联动
         * @param {DeviceLinkVo} deviceLinkVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceLinkdevicelink: async (deviceLinkVo: DeviceLinkVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceLinkVo' is not null or undefined
            assertParamExists('addDeviceLinkdevicelink', 'deviceLinkVo', deviceLinkVo)
            const localVarPath = `/devicelink/addDeviceLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceLinkVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除设备联动
         * @param {number} deviceLinkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delDeviceLinkdevicelink: async (deviceLinkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceLinkId' is not null or undefined
            assertParamExists('delDeviceLinkdevicelink', 'deviceLinkId', deviceLinkId)
            const localVarPath = `/devicelink/delDeviceLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceLinkId !== undefined) {
                localVarQueryParameter['deviceLinkId'] = deviceLinkId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取所有设备联动
         * @param {SystemGenerateObject62} systemGenerateObject62 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceLinkdevicelink: async (systemGenerateObject62: SystemGenerateObject62, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject62' is not null or undefined
            assertParamExists('listDeviceLinkdevicelink', 'systemGenerateObject62', systemGenerateObject62)
            const localVarPath = `/devicelink/listDeviceLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject62, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新设备联动
         * @param {DeviceLinkVo} deviceLinkVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceLinkdevicelink: async (deviceLinkVo: DeviceLinkVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceLinkVo' is not null or undefined
            assertParamExists('updateDeviceLinkdevicelink', 'deviceLinkVo', deviceLinkVo)
            const localVarPath = `/devicelink/updateDeviceLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceLinkVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevicelinkApi - functional programming interface
 * @export
 */
export const DevicelinkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevicelinkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加设备联动
         * @param {DeviceLinkVo} deviceLinkVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDeviceLinkdevicelink(deviceLinkVo: DeviceLinkVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDeviceLinkdevicelink(deviceLinkVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除设备联动
         * @param {number} deviceLinkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delDeviceLinkdevicelink(deviceLinkId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delDeviceLinkdevicelink(deviceLinkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取所有设备联动
         * @param {SystemGenerateObject62} systemGenerateObject62 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDeviceLinkdevicelink(systemGenerateObject62: SystemGenerateObject62, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDeviceLinkdevicelink(systemGenerateObject62, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新设备联动
         * @param {DeviceLinkVo} deviceLinkVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeviceLinkdevicelink(deviceLinkVo: DeviceLinkVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeviceLinkdevicelink(deviceLinkVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevicelinkApi - factory interface
 * @export
 */
export const DevicelinkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevicelinkApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加设备联动
         * @param {DeviceLinkVo} deviceLinkVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceLinkdevicelink(deviceLinkVo: DeviceLinkVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addDeviceLinkdevicelink(deviceLinkVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除设备联动
         * @param {number} deviceLinkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delDeviceLinkdevicelink(deviceLinkId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delDeviceLinkdevicelink(deviceLinkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取所有设备联动
         * @param {SystemGenerateObject62} systemGenerateObject62 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceLinkdevicelink(systemGenerateObject62: SystemGenerateObject62, options?: any): AxiosPromise<any> {
            return localVarFp.listDeviceLinkdevicelink(systemGenerateObject62, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新设备联动
         * @param {DeviceLinkVo} deviceLinkVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceLinkdevicelink(deviceLinkVo: DeviceLinkVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateDeviceLinkdevicelink(deviceLinkVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevicelinkApi - object-oriented interface
 * @export
 * @class DevicelinkApi
 * @extends {BaseAPI}
 */
export class DevicelinkApi extends BaseAPI {
    /**
     * 
     * @summary 添加设备联动
     * @param {DeviceLinkVo} deviceLinkVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicelinkApi
     */
    public addDeviceLinkdevicelink(deviceLinkVo: DeviceLinkVo, options?: AxiosRequestConfig) {
        return DevicelinkApiFp(this.configuration).addDeviceLinkdevicelink(deviceLinkVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除设备联动
     * @param {number} deviceLinkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicelinkApi
     */
    public delDeviceLinkdevicelink(deviceLinkId: number, options?: AxiosRequestConfig) {
        return DevicelinkApiFp(this.configuration).delDeviceLinkdevicelink(deviceLinkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取所有设备联动
     * @param {SystemGenerateObject62} systemGenerateObject62 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicelinkApi
     */
    public listDeviceLinkdevicelink(systemGenerateObject62: SystemGenerateObject62, options?: AxiosRequestConfig) {
        return DevicelinkApiFp(this.configuration).listDeviceLinkdevicelink(systemGenerateObject62, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新设备联动
     * @param {DeviceLinkVo} deviceLinkVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicelinkApi
     */
    public updateDeviceLinkdevicelink(deviceLinkVo: DeviceLinkVo, options?: AxiosRequestConfig) {
        return DevicelinkApiFp(this.configuration).updateDeviceLinkdevicelink(deviceLinkVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DouyinPackageApi - axios parameter creator
 * @export
 */
export const DouyinPackageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取店铺抖音商品列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDouyinPackageDouyinPackage: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('listDouyinPackageDouyinPackage', 'storeId', storeId)
            const localVarPath = `/douyinpackage/listDouyinPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新店铺抖音商品
         * @param {DouyinPackageAdminVo} douyinPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDouyinDouyinPackage: async (douyinPackageAdminVo: DouyinPackageAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'douyinPackageAdminVo' is not null or undefined
            assertParamExists('updateDouyinDouyinPackage', 'douyinPackageAdminVo', douyinPackageAdminVo)
            const localVarPath = `/douyinpackage/updateDouyin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(douyinPackageAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 核销抖音券
         * @param {SystemGenerateObject59} systemGenerateObject59 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useDouyinDouyinPackage: async (systemGenerateObject59: SystemGenerateObject59, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject59' is not null or undefined
            assertParamExists('useDouyinDouyinPackage', 'systemGenerateObject59', systemGenerateObject59)
            const localVarPath = `/douyinpackage/useDouyin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject59, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary webhook
         * @param {SystemGenerateObject58} systemGenerateObject58 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookDouyinPackage: async (systemGenerateObject58: SystemGenerateObject58, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject58' is not null or undefined
            assertParamExists('webhookDouyinPackage', 'systemGenerateObject58', systemGenerateObject58)
            const localVarPath = `/douyinpackage/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject58, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DouyinPackageApi - functional programming interface
 * @export
 */
export const DouyinPackageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DouyinPackageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取店铺抖音商品列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDouyinPackageDouyinPackage(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDouyinPackageDouyinPackage(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新店铺抖音商品
         * @param {DouyinPackageAdminVo} douyinPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDouyinDouyinPackage(douyinPackageAdminVo: DouyinPackageAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDouyinDouyinPackage(douyinPackageAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 核销抖音券
         * @param {SystemGenerateObject59} systemGenerateObject59 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useDouyinDouyinPackage(systemGenerateObject59: SystemGenerateObject59, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useDouyinDouyinPackage(systemGenerateObject59, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary webhook
         * @param {SystemGenerateObject58} systemGenerateObject58 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhookDouyinPackage(systemGenerateObject58: SystemGenerateObject58, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhookDouyinPackage(systemGenerateObject58, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DouyinPackageApi - factory interface
 * @export
 */
export const DouyinPackageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DouyinPackageApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取店铺抖音商品列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDouyinPackageDouyinPackage(storeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.listDouyinPackageDouyinPackage(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新店铺抖音商品
         * @param {DouyinPackageAdminVo} douyinPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDouyinDouyinPackage(douyinPackageAdminVo: DouyinPackageAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateDouyinDouyinPackage(douyinPackageAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 核销抖音券
         * @param {SystemGenerateObject59} systemGenerateObject59 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useDouyinDouyinPackage(systemGenerateObject59: SystemGenerateObject59, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.useDouyinDouyinPackage(systemGenerateObject59, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary webhook
         * @param {SystemGenerateObject58} systemGenerateObject58 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookDouyinPackage(systemGenerateObject58: SystemGenerateObject58, options?: any): AxiosPromise<any> {
            return localVarFp.webhookDouyinPackage(systemGenerateObject58, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DouyinPackageApi - object-oriented interface
 * @export
 * @class DouyinPackageApi
 * @extends {BaseAPI}
 */
export class DouyinPackageApi extends BaseAPI {
    /**
     * 
     * @summary 获取店铺抖音商品列表
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DouyinPackageApi
     */
    public listDouyinPackageDouyinPackage(storeId: number, options?: AxiosRequestConfig) {
        return DouyinPackageApiFp(this.configuration).listDouyinPackageDouyinPackage(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新店铺抖音商品
     * @param {DouyinPackageAdminVo} douyinPackageAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DouyinPackageApi
     */
    public updateDouyinDouyinPackage(douyinPackageAdminVo: DouyinPackageAdminVo, options?: AxiosRequestConfig) {
        return DouyinPackageApiFp(this.configuration).updateDouyinDouyinPackage(douyinPackageAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 核销抖音券
     * @param {SystemGenerateObject59} systemGenerateObject59 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DouyinPackageApi
     */
    public useDouyinDouyinPackage(systemGenerateObject59: SystemGenerateObject59, options?: AxiosRequestConfig) {
        return DouyinPackageApiFp(this.configuration).useDouyinDouyinPackage(systemGenerateObject59, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary webhook
     * @param {SystemGenerateObject58} systemGenerateObject58 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DouyinPackageApi
     */
    public webhookDouyinPackage(systemGenerateObject58: SystemGenerateObject58, options?: AxiosRequestConfig) {
        return DouyinPackageApiFp(this.configuration).webhookDouyinPackage(systemGenerateObject58, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HourPackageApi - axios parameter creator
 * @export
 */
export const HourPackageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加小时套餐
         * @param {HourPackageAdminVo} hourPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHourPackageHourPackage: async (hourPackageAdminVo: HourPackageAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hourPackageAdminVo' is not null or undefined
            assertParamExists('addHourPackageHourPackage', 'hourPackageAdminVo', hourPackageAdminVo)
            const localVarPath = `/hourpackage/addHourPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hourPackageAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 批量生成小时券
         * @param {SystemGenerateObject43} systemGenerateObject43 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchGeneratorHourTicketHourPackage: async (systemGenerateObject43: SystemGenerateObject43, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject43' is not null or undefined
            assertParamExists('batchGeneratorHourTicketHourPackage', 'systemGenerateObject43', systemGenerateObject43)
            const localVarPath = `/hourpackage/batchGeneratorHourTicket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject43, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 批量修改
         * @param {SystemGenerateObject46} systemGenerateObject46 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateTicketHourPackage: async (systemGenerateObject46: SystemGenerateObject46, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject46' is not null or undefined
            assertParamExists('batchUpdateTicketHourPackage', 'systemGenerateObject46', systemGenerateObject46)
            const localVarPath = `/hourpackage/batchUpdateTicket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject46, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除小时套餐
         * @param {number} hourPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delHourPackageHourPackage: async (hourPackageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hourPackageId' is not null or undefined
            assertParamExists('delHourPackageHourPackage', 'hourPackageId', hourPackageId)
            const localVarPath = `/hourpackage/delHourPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (hourPackageId !== undefined) {
                localVarQueryParameter['hourPackageId'] = hourPackageId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 生成指定用户小时券
         * @param {SystemGenerateObject44} systemGenerateObject44 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatorHourTicketHourPackage: async (systemGenerateObject44: SystemGenerateObject44, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject44' is not null or undefined
            assertParamExists('generatorHourTicketHourPackage', 'systemGenerateObject44', systemGenerateObject44)
            const localVarPath = `/hourpackage/generatorHourTicket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject44, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取客户端店铺小时套餐列表
         * @param {number} storeId 
         * @param {number} areaTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientHourPackageHourPackage: async (storeId: number, areaTypeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('listClientHourPackageHourPackage', 'storeId', storeId)
            // verify required parameter 'areaTypeId' is not null or undefined
            assertParamExists('listClientHourPackageHourPackage', 'areaTypeId', areaTypeId)
            const localVarPath = `/hourpackage/listClientHourPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (areaTypeId !== undefined) {
                localVarQueryParameter['areaTypeId'] = areaTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户的小时券列表
         * @param {string} storeId 
         * @param {number} [typeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientHourTicketHourPackage: async (storeId: string, typeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('listClientHourTicketHourPackage', 'storeId', storeId)
            const localVarPath = `/hourpackage/listClientHourTicket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (typeId !== undefined) {
                localVarQueryParameter['typeId'] = typeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取小时套餐列表
         * @param {SystemGenerateObject39} systemGenerateObject39 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHourPackageHourPackage: async (systemGenerateObject39: SystemGenerateObject39, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject39' is not null or undefined
            assertParamExists('listHourPackageHourPackage', 'systemGenerateObject39', systemGenerateObject39)
            const localVarPath = `/hourpackage/listHourPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject39, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取小时券列表
         * @param {SystemGenerateObject41} systemGenerateObject41 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHourTicketHourPackage: async (systemGenerateObject41: SystemGenerateObject41, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject41' is not null or undefined
            assertParamExists('listHourTicketHourPackage', 'systemGenerateObject41', systemGenerateObject41)
            const localVarPath = `/hourpackage/listHourTicket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject41, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取小时套餐列表(分页)
         * @param {SystemGenerateObject40} systemGenerateObject40 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageHourPackageHourPackage: async (systemGenerateObject40: SystemGenerateObject40, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject40' is not null or undefined
            assertParamExists('pageHourPackageHourPackage', 'systemGenerateObject40', systemGenerateObject40)
            const localVarPath = `/hourpackage/pageHourPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject40, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取小时券列表(分页)
         * @param {SystemGenerateObject42} systemGenerateObject42 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageHourTicketHourPackage: async (systemGenerateObject42: SystemGenerateObject42, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject42' is not null or undefined
            assertParamExists('pageHourTicketHourPackage', 'systemGenerateObject42', systemGenerateObject42)
            const localVarPath = `/hourpackage/pageHourTicket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject42, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 购买小时券（下单）
         * @param {SystemGenerateObject47} systemGenerateObject47 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepHourPackageClientHourPackage: async (systemGenerateObject47: SystemGenerateObject47, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject47' is not null or undefined
            assertParamExists('prepHourPackageClientHourPackage', 'systemGenerateObject47', systemGenerateObject47)
            const localVarPath = `/hourpackage/prepHourPackageClient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject47, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新小时套餐
         * @param {HourPackageAdminVo} hourPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHourPackageHourPackage: async (hourPackageAdminVo: HourPackageAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hourPackageAdminVo' is not null or undefined
            assertParamExists('updateHourPackageHourPackage', 'hourPackageAdminVo', hourPackageAdminVo)
            const localVarPath = `/hourpackage/updateHourPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hourPackageAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 使用小时券
         * @param {SystemGenerateObject45} systemGenerateObject45 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useHourTicketHourPackage: async (systemGenerateObject45: SystemGenerateObject45, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject45' is not null or undefined
            assertParamExists('useHourTicketHourPackage', 'systemGenerateObject45', systemGenerateObject45)
            const localVarPath = `/hourpackage/useHourTicket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject45, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HourPackageApi - functional programming interface
 * @export
 */
export const HourPackageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HourPackageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加小时套餐
         * @param {HourPackageAdminVo} hourPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addHourPackageHourPackage(hourPackageAdminVo: HourPackageAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addHourPackageHourPackage(hourPackageAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 批量生成小时券
         * @param {SystemGenerateObject43} systemGenerateObject43 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchGeneratorHourTicketHourPackage(systemGenerateObject43: SystemGenerateObject43, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchGeneratorHourTicketHourPackage(systemGenerateObject43, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 批量修改
         * @param {SystemGenerateObject46} systemGenerateObject46 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateTicketHourPackage(systemGenerateObject46: SystemGenerateObject46, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateTicketHourPackage(systemGenerateObject46, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除小时套餐
         * @param {number} hourPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delHourPackageHourPackage(hourPackageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delHourPackageHourPackage(hourPackageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 生成指定用户小时券
         * @param {SystemGenerateObject44} systemGenerateObject44 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatorHourTicketHourPackage(systemGenerateObject44: SystemGenerateObject44, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatorHourTicketHourPackage(systemGenerateObject44, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取客户端店铺小时套餐列表
         * @param {number} storeId 
         * @param {number} areaTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClientHourPackageHourPackage(storeId: number, areaTypeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClientHourPackageHourPackage(storeId, areaTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户的小时券列表
         * @param {string} storeId 
         * @param {number} [typeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClientHourTicketHourPackage(storeId: string, typeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClientHourTicketHourPackage(storeId, typeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取小时套餐列表
         * @param {SystemGenerateObject39} systemGenerateObject39 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHourPackageHourPackage(systemGenerateObject39: SystemGenerateObject39, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHourPackageHourPackage(systemGenerateObject39, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取小时券列表
         * @param {SystemGenerateObject41} systemGenerateObject41 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHourTicketHourPackage(systemGenerateObject41: SystemGenerateObject41, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHourTicketHourPackage(systemGenerateObject41, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取小时套餐列表(分页)
         * @param {SystemGenerateObject40} systemGenerateObject40 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageHourPackageHourPackage(systemGenerateObject40: SystemGenerateObject40, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageHourPackageHourPackage(systemGenerateObject40, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取小时券列表(分页)
         * @param {SystemGenerateObject42} systemGenerateObject42 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageHourTicketHourPackage(systemGenerateObject42: SystemGenerateObject42, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageHourTicketHourPackage(systemGenerateObject42, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 购买小时券（下单）
         * @param {SystemGenerateObject47} systemGenerateObject47 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prepHourPackageClientHourPackage(systemGenerateObject47: SystemGenerateObject47, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prepHourPackageClientHourPackage(systemGenerateObject47, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新小时套餐
         * @param {HourPackageAdminVo} hourPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHourPackageHourPackage(hourPackageAdminVo: HourPackageAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHourPackageHourPackage(hourPackageAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 使用小时券
         * @param {SystemGenerateObject45} systemGenerateObject45 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useHourTicketHourPackage(systemGenerateObject45: SystemGenerateObject45, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useHourTicketHourPackage(systemGenerateObject45, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HourPackageApi - factory interface
 * @export
 */
export const HourPackageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HourPackageApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加小时套餐
         * @param {HourPackageAdminVo} hourPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHourPackageHourPackage(hourPackageAdminVo: HourPackageAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addHourPackageHourPackage(hourPackageAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 批量生成小时券
         * @param {SystemGenerateObject43} systemGenerateObject43 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchGeneratorHourTicketHourPackage(systemGenerateObject43: SystemGenerateObject43, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.batchGeneratorHourTicketHourPackage(systemGenerateObject43, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 批量修改
         * @param {SystemGenerateObject46} systemGenerateObject46 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateTicketHourPackage(systemGenerateObject46: SystemGenerateObject46, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.batchUpdateTicketHourPackage(systemGenerateObject46, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除小时套餐
         * @param {number} hourPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delHourPackageHourPackage(hourPackageId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delHourPackageHourPackage(hourPackageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 生成指定用户小时券
         * @param {SystemGenerateObject44} systemGenerateObject44 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatorHourTicketHourPackage(systemGenerateObject44: SystemGenerateObject44, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.generatorHourTicketHourPackage(systemGenerateObject44, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取客户端店铺小时套餐列表
         * @param {number} storeId 
         * @param {number} areaTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientHourPackageHourPackage(storeId: number, areaTypeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.listClientHourPackageHourPackage(storeId, areaTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户的小时券列表
         * @param {string} storeId 
         * @param {number} [typeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientHourTicketHourPackage(storeId: string, typeId?: number, options?: any): AxiosPromise<any> {
            return localVarFp.listClientHourTicketHourPackage(storeId, typeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取小时套餐列表
         * @param {SystemGenerateObject39} systemGenerateObject39 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHourPackageHourPackage(systemGenerateObject39: SystemGenerateObject39, options?: any): AxiosPromise<any> {
            return localVarFp.listHourPackageHourPackage(systemGenerateObject39, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取小时券列表
         * @param {SystemGenerateObject41} systemGenerateObject41 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHourTicketHourPackage(systemGenerateObject41: SystemGenerateObject41, options?: any): AxiosPromise<any> {
            return localVarFp.listHourTicketHourPackage(systemGenerateObject41, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取小时套餐列表(分页)
         * @param {SystemGenerateObject40} systemGenerateObject40 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageHourPackageHourPackage(systemGenerateObject40: SystemGenerateObject40, options?: any): AxiosPromise<any> {
            return localVarFp.pageHourPackageHourPackage(systemGenerateObject40, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取小时券列表(分页)
         * @param {SystemGenerateObject42} systemGenerateObject42 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageHourTicketHourPackage(systemGenerateObject42: SystemGenerateObject42, options?: any): AxiosPromise<any> {
            return localVarFp.pageHourTicketHourPackage(systemGenerateObject42, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 购买小时券（下单）
         * @param {SystemGenerateObject47} systemGenerateObject47 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepHourPackageClientHourPackage(systemGenerateObject47: SystemGenerateObject47, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.prepHourPackageClientHourPackage(systemGenerateObject47, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新小时套餐
         * @param {HourPackageAdminVo} hourPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHourPackageHourPackage(hourPackageAdminVo: HourPackageAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateHourPackageHourPackage(hourPackageAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 使用小时券
         * @param {SystemGenerateObject45} systemGenerateObject45 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useHourTicketHourPackage(systemGenerateObject45: SystemGenerateObject45, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.useHourTicketHourPackage(systemGenerateObject45, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HourPackageApi - object-oriented interface
 * @export
 * @class HourPackageApi
 * @extends {BaseAPI}
 */
export class HourPackageApi extends BaseAPI {
    /**
     * 
     * @summary 添加小时套餐
     * @param {HourPackageAdminVo} hourPackageAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public addHourPackageHourPackage(hourPackageAdminVo: HourPackageAdminVo, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).addHourPackageHourPackage(hourPackageAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 批量生成小时券
     * @param {SystemGenerateObject43} systemGenerateObject43 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public batchGeneratorHourTicketHourPackage(systemGenerateObject43: SystemGenerateObject43, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).batchGeneratorHourTicketHourPackage(systemGenerateObject43, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 批量修改
     * @param {SystemGenerateObject46} systemGenerateObject46 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public batchUpdateTicketHourPackage(systemGenerateObject46: SystemGenerateObject46, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).batchUpdateTicketHourPackage(systemGenerateObject46, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除小时套餐
     * @param {number} hourPackageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public delHourPackageHourPackage(hourPackageId: number, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).delHourPackageHourPackage(hourPackageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 生成指定用户小时券
     * @param {SystemGenerateObject44} systemGenerateObject44 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public generatorHourTicketHourPackage(systemGenerateObject44: SystemGenerateObject44, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).generatorHourTicketHourPackage(systemGenerateObject44, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取客户端店铺小时套餐列表
     * @param {number} storeId 
     * @param {number} areaTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public listClientHourPackageHourPackage(storeId: number, areaTypeId: number, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).listClientHourPackageHourPackage(storeId, areaTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户的小时券列表
     * @param {string} storeId 
     * @param {number} [typeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public listClientHourTicketHourPackage(storeId: string, typeId?: number, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).listClientHourTicketHourPackage(storeId, typeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取小时套餐列表
     * @param {SystemGenerateObject39} systemGenerateObject39 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public listHourPackageHourPackage(systemGenerateObject39: SystemGenerateObject39, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).listHourPackageHourPackage(systemGenerateObject39, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取小时券列表
     * @param {SystemGenerateObject41} systemGenerateObject41 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public listHourTicketHourPackage(systemGenerateObject41: SystemGenerateObject41, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).listHourTicketHourPackage(systemGenerateObject41, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取小时套餐列表(分页)
     * @param {SystemGenerateObject40} systemGenerateObject40 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public pageHourPackageHourPackage(systemGenerateObject40: SystemGenerateObject40, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).pageHourPackageHourPackage(systemGenerateObject40, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取小时券列表(分页)
     * @param {SystemGenerateObject42} systemGenerateObject42 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public pageHourTicketHourPackage(systemGenerateObject42: SystemGenerateObject42, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).pageHourTicketHourPackage(systemGenerateObject42, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 购买小时券（下单）
     * @param {SystemGenerateObject47} systemGenerateObject47 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public prepHourPackageClientHourPackage(systemGenerateObject47: SystemGenerateObject47, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).prepHourPackageClientHourPackage(systemGenerateObject47, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新小时套餐
     * @param {HourPackageAdminVo} hourPackageAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public updateHourPackageHourPackage(hourPackageAdminVo: HourPackageAdminVo, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).updateHourPackageHourPackage(hourPackageAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 使用小时券
     * @param {SystemGenerateObject45} systemGenerateObject45 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HourPackageApi
     */
    public useHourTicketHourPackage(systemGenerateObject45: SystemGenerateObject45, options?: AxiosRequestConfig) {
        return HourPackageApiFp(this.configuration).useHourTicketHourPackage(systemGenerateObject45, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MeituanPackageApi - axios parameter creator
 * @export
 */
export const MeituanPackageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 授权店铺
         * @param {SystemGenerateObject54} systemGenerateObject54 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authMeituanMeituanPackage: async (systemGenerateObject54: SystemGenerateObject54, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject54' is not null or undefined
            assertParamExists('authMeituanMeituanPackage', 'systemGenerateObject54', systemGenerateObject54)
            const localVarPath = `/meituanpackage/authMeituan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject54, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取设备店铺美团商品列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMeituanPackageMeituanPackage: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('listMeituanPackageMeituanPackage', 'storeId', storeId)
            const localVarPath = `/meituanpackage/listMeituanPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新设备店铺美团商品
         * @param {MeituanPackageAdminVo} meituanPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeituanMeituanPackage: async (meituanPackageAdminVo: MeituanPackageAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meituanPackageAdminVo' is not null or undefined
            assertParamExists('updateMeituanMeituanPackage', 'meituanPackageAdminVo', meituanPackageAdminVo)
            const localVarPath = `/meituanpackage/updateMeituan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meituanPackageAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 核销美团券
         * @param {SystemGenerateObject55} systemGenerateObject55 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useMeituanMeituanPackage: async (systemGenerateObject55: SystemGenerateObject55, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject55' is not null or undefined
            assertParamExists('useMeituanMeituanPackage', 'systemGenerateObject55', systemGenerateObject55)
            const localVarPath = `/meituanpackage/useMeituan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject55, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeituanPackageApi - functional programming interface
 * @export
 */
export const MeituanPackageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeituanPackageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 授权店铺
         * @param {SystemGenerateObject54} systemGenerateObject54 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authMeituanMeituanPackage(systemGenerateObject54: SystemGenerateObject54, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authMeituanMeituanPackage(systemGenerateObject54, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取设备店铺美团商品列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMeituanPackageMeituanPackage(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMeituanPackageMeituanPackage(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新设备店铺美团商品
         * @param {MeituanPackageAdminVo} meituanPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeituanMeituanPackage(meituanPackageAdminVo: MeituanPackageAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeituanMeituanPackage(meituanPackageAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 核销美团券
         * @param {SystemGenerateObject55} systemGenerateObject55 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useMeituanMeituanPackage(systemGenerateObject55: SystemGenerateObject55, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useMeituanMeituanPackage(systemGenerateObject55, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeituanPackageApi - factory interface
 * @export
 */
export const MeituanPackageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeituanPackageApiFp(configuration)
    return {
        /**
         * 
         * @summary 授权店铺
         * @param {SystemGenerateObject54} systemGenerateObject54 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authMeituanMeituanPackage(systemGenerateObject54: SystemGenerateObject54, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.authMeituanMeituanPackage(systemGenerateObject54, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取设备店铺美团商品列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMeituanPackageMeituanPackage(storeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.listMeituanPackageMeituanPackage(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新设备店铺美团商品
         * @param {MeituanPackageAdminVo} meituanPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeituanMeituanPackage(meituanPackageAdminVo: MeituanPackageAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateMeituanMeituanPackage(meituanPackageAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 核销美团券
         * @param {SystemGenerateObject55} systemGenerateObject55 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useMeituanMeituanPackage(systemGenerateObject55: SystemGenerateObject55, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.useMeituanMeituanPackage(systemGenerateObject55, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeituanPackageApi - object-oriented interface
 * @export
 * @class MeituanPackageApi
 * @extends {BaseAPI}
 */
export class MeituanPackageApi extends BaseAPI {
    /**
     * 
     * @summary 授权店铺
     * @param {SystemGenerateObject54} systemGenerateObject54 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeituanPackageApi
     */
    public authMeituanMeituanPackage(systemGenerateObject54: SystemGenerateObject54, options?: AxiosRequestConfig) {
        return MeituanPackageApiFp(this.configuration).authMeituanMeituanPackage(systemGenerateObject54, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取设备店铺美团商品列表
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeituanPackageApi
     */
    public listMeituanPackageMeituanPackage(storeId: number, options?: AxiosRequestConfig) {
        return MeituanPackageApiFp(this.configuration).listMeituanPackageMeituanPackage(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新设备店铺美团商品
     * @param {MeituanPackageAdminVo} meituanPackageAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeituanPackageApi
     */
    public updateMeituanMeituanPackage(meituanPackageAdminVo: MeituanPackageAdminVo, options?: AxiosRequestConfig) {
        return MeituanPackageApiFp(this.configuration).updateMeituanMeituanPackage(meituanPackageAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 核销美团券
     * @param {SystemGenerateObject55} systemGenerateObject55 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeituanPackageApi
     */
    public useMeituanMeituanPackage(systemGenerateObject55: SystemGenerateObject55, options?: AxiosRequestConfig) {
        return MeituanPackageApiFp(this.configuration).useMeituanMeituanPackage(systemGenerateObject55, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PayPackageApi - axios parameter creator
 * @export
 */
export const PayPackageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加充值套餐
         * @param {PayPackageAdminVo} payPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPayPackagePayPackage: async (payPackageAdminVo: PayPackageAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payPackageAdminVo' is not null or undefined
            assertParamExists('addPayPackagePayPackage', 'payPackageAdminVo', payPackageAdminVo)
            const localVarPath = `/paypackage/addPayPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payPackageAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除充值套餐
         * @param {number} payPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delPayPackagePayPackage: async (payPackageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payPackageId' is not null or undefined
            assertParamExists('delPayPackagePayPackage', 'payPackageId', payPackageId)
            const localVarPath = `/paypackage/delPayPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (payPackageId !== undefined) {
                localVarQueryParameter['payPackageId'] = payPackageId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取客户端店铺充值套餐列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientPayPackagePayPackage: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('listClientPayPackagePayPackage', 'storeId', storeId)
            const localVarPath = `/paypackage/listClientPayPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取充值套餐列表
         * @param {SystemGenerateObject36} systemGenerateObject36 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayPackagePayPackage: async (systemGenerateObject36: SystemGenerateObject36, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject36' is not null or undefined
            assertParamExists('listPayPackagePayPackage', 'systemGenerateObject36', systemGenerateObject36)
            const localVarPath = `/paypackage/listPayPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject36, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取充值套餐列表(分页)
         * @param {SystemGenerateObject37} systemGenerateObject37 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagePayPackagePayPackage: async (systemGenerateObject37: SystemGenerateObject37, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject37' is not null or undefined
            assertParamExists('pagePayPackagePayPackage', 'systemGenerateObject37', systemGenerateObject37)
            const localVarPath = `/paypackage/pagePayPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject37, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 预充值（下单）
         * @param {SystemGenerateObject38} systemGenerateObject38 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepPayPackageClientPayPackage: async (systemGenerateObject38: SystemGenerateObject38, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject38' is not null or undefined
            assertParamExists('prepPayPackageClientPayPackage', 'systemGenerateObject38', systemGenerateObject38)
            const localVarPath = `/paypackage/prepPayPackageClient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject38, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新充值套餐
         * @param {PayPackageAdminVo} payPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePayPackagePayPackage: async (payPackageAdminVo: PayPackageAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payPackageAdminVo' is not null or undefined
            assertParamExists('updatePayPackagePayPackage', 'payPackageAdminVo', payPackageAdminVo)
            const localVarPath = `/paypackage/updatePayPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payPackageAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayPackageApi - functional programming interface
 * @export
 */
export const PayPackageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayPackageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加充值套餐
         * @param {PayPackageAdminVo} payPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPayPackagePayPackage(payPackageAdminVo: PayPackageAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPayPackagePayPackage(payPackageAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除充值套餐
         * @param {number} payPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delPayPackagePayPackage(payPackageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delPayPackagePayPackage(payPackageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取客户端店铺充值套餐列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClientPayPackagePayPackage(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClientPayPackagePayPackage(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取充值套餐列表
         * @param {SystemGenerateObject36} systemGenerateObject36 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPayPackagePayPackage(systemGenerateObject36: SystemGenerateObject36, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPayPackagePayPackage(systemGenerateObject36, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取充值套餐列表(分页)
         * @param {SystemGenerateObject37} systemGenerateObject37 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pagePayPackagePayPackage(systemGenerateObject37: SystemGenerateObject37, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pagePayPackagePayPackage(systemGenerateObject37, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 预充值（下单）
         * @param {SystemGenerateObject38} systemGenerateObject38 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prepPayPackageClientPayPackage(systemGenerateObject38: SystemGenerateObject38, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prepPayPackageClientPayPackage(systemGenerateObject38, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新充值套餐
         * @param {PayPackageAdminVo} payPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePayPackagePayPackage(payPackageAdminVo: PayPackageAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePayPackagePayPackage(payPackageAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PayPackageApi - factory interface
 * @export
 */
export const PayPackageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayPackageApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加充值套餐
         * @param {PayPackageAdminVo} payPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPayPackagePayPackage(payPackageAdminVo: PayPackageAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addPayPackagePayPackage(payPackageAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除充值套餐
         * @param {number} payPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delPayPackagePayPackage(payPackageId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delPayPackagePayPackage(payPackageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取客户端店铺充值套餐列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientPayPackagePayPackage(storeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.listClientPayPackagePayPackage(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取充值套餐列表
         * @param {SystemGenerateObject36} systemGenerateObject36 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayPackagePayPackage(systemGenerateObject36: SystemGenerateObject36, options?: any): AxiosPromise<any> {
            return localVarFp.listPayPackagePayPackage(systemGenerateObject36, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取充值套餐列表(分页)
         * @param {SystemGenerateObject37} systemGenerateObject37 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagePayPackagePayPackage(systemGenerateObject37: SystemGenerateObject37, options?: any): AxiosPromise<any> {
            return localVarFp.pagePayPackagePayPackage(systemGenerateObject37, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 预充值（下单）
         * @param {SystemGenerateObject38} systemGenerateObject38 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepPayPackageClientPayPackage(systemGenerateObject38: SystemGenerateObject38, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.prepPayPackageClientPayPackage(systemGenerateObject38, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新充值套餐
         * @param {PayPackageAdminVo} payPackageAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePayPackagePayPackage(payPackageAdminVo: PayPackageAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updatePayPackagePayPackage(payPackageAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayPackageApi - object-oriented interface
 * @export
 * @class PayPackageApi
 * @extends {BaseAPI}
 */
export class PayPackageApi extends BaseAPI {
    /**
     * 
     * @summary 添加充值套餐
     * @param {PayPackageAdminVo} payPackageAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayPackageApi
     */
    public addPayPackagePayPackage(payPackageAdminVo: PayPackageAdminVo, options?: AxiosRequestConfig) {
        return PayPackageApiFp(this.configuration).addPayPackagePayPackage(payPackageAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除充值套餐
     * @param {number} payPackageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayPackageApi
     */
    public delPayPackagePayPackage(payPackageId: number, options?: AxiosRequestConfig) {
        return PayPackageApiFp(this.configuration).delPayPackagePayPackage(payPackageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取客户端店铺充值套餐列表
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayPackageApi
     */
    public listClientPayPackagePayPackage(storeId: number, options?: AxiosRequestConfig) {
        return PayPackageApiFp(this.configuration).listClientPayPackagePayPackage(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取充值套餐列表
     * @param {SystemGenerateObject36} systemGenerateObject36 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayPackageApi
     */
    public listPayPackagePayPackage(systemGenerateObject36: SystemGenerateObject36, options?: AxiosRequestConfig) {
        return PayPackageApiFp(this.configuration).listPayPackagePayPackage(systemGenerateObject36, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取充值套餐列表(分页)
     * @param {SystemGenerateObject37} systemGenerateObject37 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayPackageApi
     */
    public pagePayPackagePayPackage(systemGenerateObject37: SystemGenerateObject37, options?: AxiosRequestConfig) {
        return PayPackageApiFp(this.configuration).pagePayPackagePayPackage(systemGenerateObject37, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 预充值（下单）
     * @param {SystemGenerateObject38} systemGenerateObject38 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayPackageApi
     */
    public prepPayPackageClientPayPackage(systemGenerateObject38: SystemGenerateObject38, options?: AxiosRequestConfig) {
        return PayPackageApiFp(this.configuration).prepPayPackageClientPayPackage(systemGenerateObject38, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新充值套餐
     * @param {PayPackageAdminVo} payPackageAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayPackageApi
     */
    public updatePayPackagePayPackage(payPackageAdminVo: PayPackageAdminVo, options?: AxiosRequestConfig) {
        return PayPackageApiFp(this.configuration).updatePayPackagePayPackage(payPackageAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RecordApi - axios parameter creator
 * @export
 */
export const RecordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取门店记录列表
         * @param {SystemGenerateObject52} systemGenerateObject52 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecordRecord: async (systemGenerateObject52: SystemGenerateObject52, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject52' is not null or undefined
            assertParamExists('listRecordRecord', 'systemGenerateObject52', systemGenerateObject52)
            const localVarPath = `/record/listRecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject52, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取门店记录列表(分页)
         * @param {SystemGenerateObject53} systemGenerateObject53 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRecordRecord: async (systemGenerateObject53: SystemGenerateObject53, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject53' is not null or undefined
            assertParamExists('pageRecordRecord', 'systemGenerateObject53', systemGenerateObject53)
            const localVarPath = `/record/pageRecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject53, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecordApi - functional programming interface
 * @export
 */
export const RecordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取门店记录列表
         * @param {SystemGenerateObject52} systemGenerateObject52 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRecordRecord(systemGenerateObject52: SystemGenerateObject52, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRecordRecord(systemGenerateObject52, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取门店记录列表(分页)
         * @param {SystemGenerateObject53} systemGenerateObject53 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageRecordRecord(systemGenerateObject53: SystemGenerateObject53, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageRecordRecord(systemGenerateObject53, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecordApi - factory interface
 * @export
 */
export const RecordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecordApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取门店记录列表
         * @param {SystemGenerateObject52} systemGenerateObject52 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecordRecord(systemGenerateObject52: SystemGenerateObject52, options?: any): AxiosPromise<any> {
            return localVarFp.listRecordRecord(systemGenerateObject52, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取门店记录列表(分页)
         * @param {SystemGenerateObject53} systemGenerateObject53 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageRecordRecord(systemGenerateObject53: SystemGenerateObject53, options?: any): AxiosPromise<any> {
            return localVarFp.pageRecordRecord(systemGenerateObject53, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecordApi - object-oriented interface
 * @export
 * @class RecordApi
 * @extends {BaseAPI}
 */
export class RecordApi extends BaseAPI {
    /**
     * 
     * @summary 获取门店记录列表
     * @param {SystemGenerateObject52} systemGenerateObject52 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordApi
     */
    public listRecordRecord(systemGenerateObject52: SystemGenerateObject52, options?: AxiosRequestConfig) {
        return RecordApiFp(this.configuration).listRecordRecord(systemGenerateObject52, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取门店记录列表(分页)
     * @param {SystemGenerateObject53} systemGenerateObject53 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecordApi
     */
    public pageRecordRecord(systemGenerateObject53: SystemGenerateObject53, options?: AxiosRequestConfig) {
        return RecordApiFp(this.configuration).pageRecordRecord(systemGenerateObject53, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StoreApi - axios parameter creator
 * @export
 */
export const StoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加店铺
         * @param {StoreAdminVo} storeAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreStore: async (storeAdminVo: StoreAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeAdminVo' is not null or undefined
            assertParamExists('addStoreStore', 'storeAdminVo', storeAdminVo)
            const localVarPath = `/store/addStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除店铺
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreStore: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('deleteStoreStore', 'storeId', storeId)
            const localVarPath = `/store/deleteStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取单个门店信息
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminStoreStore: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAdminStoreStore', 'storeId', storeId)
            const localVarPath = `/store/getAdminStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取门店信息
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientStoreStore: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getClientStoreStore', 'storeId', storeId)
            const localVarPath = `/store/getClientStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取所有店铺列表
         * @param {SystemGenerateObject25} systemGenerateObject25 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllStoreStore: async (systemGenerateObject25: SystemGenerateObject25, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject25' is not null or undefined
            assertParamExists('listAllStoreStore', 'systemGenerateObject25', systemGenerateObject25)
            const localVarPath = `/store/listAllStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject25, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取客户端附近店铺列表
         * @param {SystemGenerateObject27} systemGenerateObject27 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientNearbyStoreStore: async (systemGenerateObject27: SystemGenerateObject27, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject27' is not null or undefined
            assertParamExists('listClientNearbyStoreStore', 'systemGenerateObject27', systemGenerateObject27)
            const localVarPath = `/store/listClientNearbyStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject27, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取所有店铺列表(分页)
         * @param {SystemGenerateObject26} systemGenerateObject26 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAllDeviceStore: async (systemGenerateObject26: SystemGenerateObject26, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject26' is not null or undefined
            assertParamExists('pageAllDeviceStore', 'systemGenerateObject26', systemGenerateObject26)
            const localVarPath = `/store/pageAllDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject26, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新店铺
         * @param {StoreAdminVo} storeAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreStore: async (storeAdminVo: StoreAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeAdminVo' is not null or undefined
            assertParamExists('updateStoreStore', 'storeAdminVo', storeAdminVo)
            const localVarPath = `/store/updateStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreApi - functional programming interface
 * @export
 */
export const StoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加店铺
         * @param {StoreAdminVo} storeAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStoreStore(storeAdminVo: StoreAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStoreStore(storeAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除店铺
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStoreStore(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStoreStore(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取单个门店信息
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminStoreStore(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminStoreStore(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取门店信息
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientStoreStore(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientStoreStore(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取所有店铺列表
         * @param {SystemGenerateObject25} systemGenerateObject25 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllStoreStore(systemGenerateObject25: SystemGenerateObject25, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllStoreStore(systemGenerateObject25, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取客户端附近店铺列表
         * @param {SystemGenerateObject27} systemGenerateObject27 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClientNearbyStoreStore(systemGenerateObject27: SystemGenerateObject27, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClientNearbyStoreStore(systemGenerateObject27, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取所有店铺列表(分页)
         * @param {SystemGenerateObject26} systemGenerateObject26 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageAllDeviceStore(systemGenerateObject26: SystemGenerateObject26, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageAllDeviceStore(systemGenerateObject26, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新店铺
         * @param {StoreAdminVo} storeAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStoreStore(storeAdminVo: StoreAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStoreStore(storeAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreApi - factory interface
 * @export
 */
export const StoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加店铺
         * @param {StoreAdminVo} storeAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreStore(storeAdminVo: StoreAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addStoreStore(storeAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除店铺
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreStore(storeId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deleteStoreStore(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取单个门店信息
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminStoreStore(storeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getAdminStoreStore(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取门店信息
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientStoreStore(storeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getClientStoreStore(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取所有店铺列表
         * @param {SystemGenerateObject25} systemGenerateObject25 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllStoreStore(systemGenerateObject25: SystemGenerateObject25, options?: any): AxiosPromise<any> {
            return localVarFp.listAllStoreStore(systemGenerateObject25, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取客户端附近店铺列表
         * @param {SystemGenerateObject27} systemGenerateObject27 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientNearbyStoreStore(systemGenerateObject27: SystemGenerateObject27, options?: any): AxiosPromise<any> {
            return localVarFp.listClientNearbyStoreStore(systemGenerateObject27, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取所有店铺列表(分页)
         * @param {SystemGenerateObject26} systemGenerateObject26 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAllDeviceStore(systemGenerateObject26: SystemGenerateObject26, options?: any): AxiosPromise<any> {
            return localVarFp.pageAllDeviceStore(systemGenerateObject26, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新店铺
         * @param {StoreAdminVo} storeAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreStore(storeAdminVo: StoreAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateStoreStore(storeAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreApi - object-oriented interface
 * @export
 * @class StoreApi
 * @extends {BaseAPI}
 */
export class StoreApi extends BaseAPI {
    /**
     * 
     * @summary 添加店铺
     * @param {StoreAdminVo} storeAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addStoreStore(storeAdminVo: StoreAdminVo, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addStoreStore(storeAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除店铺
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteStoreStore(storeId: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deleteStoreStore(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取单个门店信息
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getAdminStoreStore(storeId: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getAdminStoreStore(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取门店信息
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getClientStoreStore(storeId: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getClientStoreStore(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取所有店铺列表
     * @param {SystemGenerateObject25} systemGenerateObject25 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public listAllStoreStore(systemGenerateObject25: SystemGenerateObject25, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).listAllStoreStore(systemGenerateObject25, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取客户端附近店铺列表
     * @param {SystemGenerateObject27} systemGenerateObject27 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public listClientNearbyStoreStore(systemGenerateObject27: SystemGenerateObject27, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).listClientNearbyStoreStore(systemGenerateObject27, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取所有店铺列表(分页)
     * @param {SystemGenerateObject26} systemGenerateObject26 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public pageAllDeviceStore(systemGenerateObject26: SystemGenerateObject26, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).pageAllDeviceStore(systemGenerateObject26, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新店铺
     * @param {StoreAdminVo} storeAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public updateStoreStore(storeAdminVo: StoreAdminVo, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).updateStoreStore(storeAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StoreCustomerApi - axios parameter creator
 * @export
 */
export const StoreCustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 押金结束
         * @param {SystemGenerateObject31} systemGenerateObject31 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPledgeStopStoreCustomer: async (systemGenerateObject31: SystemGenerateObject31, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject31' is not null or undefined
            assertParamExists('cashPledgeStopStoreCustomer', 'systemGenerateObject31', systemGenerateObject31)
            const localVarPath = `/storecustomer/cashPledgeStop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject31, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 用户手动停止
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerStopStoreCustomer: async (storeDeviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDeviceId' is not null or undefined
            assertParamExists('customerStopStoreCustomer', 'storeDeviceId', storeDeviceId)
            const localVarPath = `/storecustomer/customerStop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeDeviceId !== undefined) {
                localVarQueryParameter['storeDeviceId'] = storeDeviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取店铺会员信息
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientStoreCustomerStoreCustomer: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getClientStoreCustomerStoreCustomer', 'storeId', storeId)
            const localVarPath = `/storecustomer/getClientStoreCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取店铺会员列表
         * @param {SystemGenerateObject28} systemGenerateObject28 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreCustomerStoreCustomer: async (systemGenerateObject28: SystemGenerateObject28, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject28' is not null or undefined
            assertParamExists('listStoreCustomerStoreCustomer', 'systemGenerateObject28', systemGenerateObject28)
            const localVarPath = `/storecustomer/listStoreCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject28, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取店铺会员列表(分页)
         * @param {SystemGenerateObject29} systemGenerateObject29 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageStoreCustomerStoreCustomer: async (systemGenerateObject29: SystemGenerateObject29, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject29' is not null or undefined
            assertParamExists('pageStoreCustomerStoreCustomer', 'systemGenerateObject29', systemGenerateObject29)
            const localVarPath = `/storecustomer/pageStoreCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject29, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 预交押金（下单）
         * @param {SystemGenerateObject30} systemGenerateObject30 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepCashPledgeStoreCustomer: async (systemGenerateObject30: SystemGenerateObject30, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject30' is not null or undefined
            assertParamExists('prepCashPledgeStoreCustomer', 'systemGenerateObject30', systemGenerateObject30)
            const localVarPath = `/storecustomer/prepCashPledge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject30, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 余额开台
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yueStartStoreCustomer: async (storeDeviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDeviceId' is not null or undefined
            assertParamExists('yueStartStoreCustomer', 'storeDeviceId', storeDeviceId)
            const localVarPath = `/storecustomer/yueStart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeDeviceId !== undefined) {
                localVarQueryParameter['storeDeviceId'] = storeDeviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreCustomerApi - functional programming interface
 * @export
 */
export const StoreCustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreCustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 押金结束
         * @param {SystemGenerateObject31} systemGenerateObject31 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashPledgeStopStoreCustomer(systemGenerateObject31: SystemGenerateObject31, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashPledgeStopStoreCustomer(systemGenerateObject31, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 用户手动停止
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerStopStoreCustomer(storeDeviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerStopStoreCustomer(storeDeviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取店铺会员信息
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientStoreCustomerStoreCustomer(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientStoreCustomerStoreCustomer(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取店铺会员列表
         * @param {SystemGenerateObject28} systemGenerateObject28 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoreCustomerStoreCustomer(systemGenerateObject28: SystemGenerateObject28, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoreCustomerStoreCustomer(systemGenerateObject28, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取店铺会员列表(分页)
         * @param {SystemGenerateObject29} systemGenerateObject29 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageStoreCustomerStoreCustomer(systemGenerateObject29: SystemGenerateObject29, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageStoreCustomerStoreCustomer(systemGenerateObject29, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 预交押金（下单）
         * @param {SystemGenerateObject30} systemGenerateObject30 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prepCashPledgeStoreCustomer(systemGenerateObject30: SystemGenerateObject30, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prepCashPledgeStoreCustomer(systemGenerateObject30, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 余额开台
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async yueStartStoreCustomer(storeDeviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.yueStartStoreCustomer(storeDeviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreCustomerApi - factory interface
 * @export
 */
export const StoreCustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreCustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary 押金结束
         * @param {SystemGenerateObject31} systemGenerateObject31 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPledgeStopStoreCustomer(systemGenerateObject31: SystemGenerateObject31, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.cashPledgeStopStoreCustomer(systemGenerateObject31, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 用户手动停止
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerStopStoreCustomer(storeDeviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.customerStopStoreCustomer(storeDeviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取店铺会员信息
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientStoreCustomerStoreCustomer(storeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getClientStoreCustomerStoreCustomer(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取店铺会员列表
         * @param {SystemGenerateObject28} systemGenerateObject28 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreCustomerStoreCustomer(systemGenerateObject28: SystemGenerateObject28, options?: any): AxiosPromise<any> {
            return localVarFp.listStoreCustomerStoreCustomer(systemGenerateObject28, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取店铺会员列表(分页)
         * @param {SystemGenerateObject29} systemGenerateObject29 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageStoreCustomerStoreCustomer(systemGenerateObject29: SystemGenerateObject29, options?: any): AxiosPromise<any> {
            return localVarFp.pageStoreCustomerStoreCustomer(systemGenerateObject29, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 预交押金（下单）
         * @param {SystemGenerateObject30} systemGenerateObject30 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepCashPledgeStoreCustomer(systemGenerateObject30: SystemGenerateObject30, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.prepCashPledgeStoreCustomer(systemGenerateObject30, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 余额开台
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yueStartStoreCustomer(storeDeviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.yueStartStoreCustomer(storeDeviceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreCustomerApi - object-oriented interface
 * @export
 * @class StoreCustomerApi
 * @extends {BaseAPI}
 */
export class StoreCustomerApi extends BaseAPI {
    /**
     * 
     * @summary 押金结束
     * @param {SystemGenerateObject31} systemGenerateObject31 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomerApi
     */
    public cashPledgeStopStoreCustomer(systemGenerateObject31: SystemGenerateObject31, options?: AxiosRequestConfig) {
        return StoreCustomerApiFp(this.configuration).cashPledgeStopStoreCustomer(systemGenerateObject31, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 用户手动停止
     * @param {number} storeDeviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomerApi
     */
    public customerStopStoreCustomer(storeDeviceId: number, options?: AxiosRequestConfig) {
        return StoreCustomerApiFp(this.configuration).customerStopStoreCustomer(storeDeviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取店铺会员信息
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomerApi
     */
    public getClientStoreCustomerStoreCustomer(storeId: number, options?: AxiosRequestConfig) {
        return StoreCustomerApiFp(this.configuration).getClientStoreCustomerStoreCustomer(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取店铺会员列表
     * @param {SystemGenerateObject28} systemGenerateObject28 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomerApi
     */
    public listStoreCustomerStoreCustomer(systemGenerateObject28: SystemGenerateObject28, options?: AxiosRequestConfig) {
        return StoreCustomerApiFp(this.configuration).listStoreCustomerStoreCustomer(systemGenerateObject28, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取店铺会员列表(分页)
     * @param {SystemGenerateObject29} systemGenerateObject29 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomerApi
     */
    public pageStoreCustomerStoreCustomer(systemGenerateObject29: SystemGenerateObject29, options?: AxiosRequestConfig) {
        return StoreCustomerApiFp(this.configuration).pageStoreCustomerStoreCustomer(systemGenerateObject29, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 预交押金（下单）
     * @param {SystemGenerateObject30} systemGenerateObject30 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomerApi
     */
    public prepCashPledgeStoreCustomer(systemGenerateObject30: SystemGenerateObject30, options?: AxiosRequestConfig) {
        return StoreCustomerApiFp(this.configuration).prepCashPledgeStoreCustomer(systemGenerateObject30, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 余额开台
     * @param {number} storeDeviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomerApi
     */
    public yueStartStoreCustomer(storeDeviceId: number, options?: AxiosRequestConfig) {
        return StoreCustomerApiFp(this.configuration).yueStartStoreCustomer(storeDeviceId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StoreDeviceApi - axios parameter creator
 * @export
 */
export const StoreDeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加门店设备联动设备
         * @param {SystemGenerateObject34} systemGenerateObject34 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreDeviceCollDeviceStoreDevice: async (systemGenerateObject34: SystemGenerateObject34, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject34' is not null or undefined
            assertParamExists('addStoreDeviceCollDeviceStoreDevice', 'systemGenerateObject34', systemGenerateObject34)
            const localVarPath = `/storedevice/addStoreDeviceCollDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject34, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加店铺设备
         * @param {StoreDeviceAdminVo} storeDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreDeviceStoreDevice: async (storeDeviceAdminVo: StoreDeviceAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDeviceAdminVo' is not null or undefined
            assertParamExists('addStoreDeviceStoreDevice', 'storeDeviceAdminVo', storeDeviceAdminVo)
            const localVarPath = `/storedevice/addStoreDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeDeviceAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除门店设备联动设备
         * @param {number} storeDeviceId 
         * @param {string} deviceIdHex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delStoreDeviceCollDeviceStoreDevice: async (storeDeviceId: number, deviceIdHex: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDeviceId' is not null or undefined
            assertParamExists('delStoreDeviceCollDeviceStoreDevice', 'storeDeviceId', storeDeviceId)
            // verify required parameter 'deviceIdHex' is not null or undefined
            assertParamExists('delStoreDeviceCollDeviceStoreDevice', 'deviceIdHex', deviceIdHex)
            const localVarPath = `/storedevice/delStoreDeviceCollDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeDeviceId !== undefined) {
                localVarQueryParameter['storeDeviceId'] = storeDeviceId;
            }

            if (deviceIdHex !== undefined) {
                localVarQueryParameter['deviceIdHex'] = deviceIdHex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除店铺设备
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delStoreDeviceStoreDevice: async (storeDeviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDeviceId' is not null or undefined
            assertParamExists('delStoreDeviceStoreDevice', 'storeDeviceId', storeDeviceId)
            const localVarPath = `/storedevice/delStoreDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeDeviceId !== undefined) {
                localVarQueryParameter['storeDeviceId'] = storeDeviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取客户端店铺设备详情
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientStoreDeviceStoreDevice: async (storeDeviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDeviceId' is not null or undefined
            assertParamExists('getClientStoreDeviceStoreDevice', 'storeDeviceId', storeDeviceId)
            const localVarPath = `/storedevice/getClientStoreDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeDeviceId !== undefined) {
                localVarQueryParameter['storeDeviceId'] = storeDeviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 当前店铺是否有门禁（客户端）
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasStoreDoorStoreDevice: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('hasStoreDoorStoreDevice', 'storeId', storeId)
            const localVarPath = `/storedevice/hasStoreDoorClient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取客户端门店设备列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientStoreDeviceStoreDevice: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('listClientStoreDeviceStoreDevice', 'storeId', storeId)
            const localVarPath = `/storedevice/listClientStoreDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取门店设备联动设备列表
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreDeviceCollDeviceStoreDevice: async (storeDeviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDeviceId' is not null or undefined
            assertParamExists('listStoreDeviceCollDeviceStoreDevice', 'storeDeviceId', storeDeviceId)
            const localVarPath = `/storedevice/listStoreDeviceCollDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeDeviceId !== undefined) {
                localVarQueryParameter['storeDeviceId'] = storeDeviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取店铺设备列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreDeviceStoreDevice: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('listStoreDeviceStoreDevice', 'storeId', storeId)
            const localVarPath = `/storedevice/listStoreDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 客户端开门
         * @param {number} storeId 
         * @param {string} [longtitude] 
         * @param {string} [latitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openStoreDoorClientStoreDevice: async (storeId: number, longtitude?: string, latitude?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('openStoreDoorClientStoreDevice', 'storeId', storeId)
            const localVarPath = `/storedevice/openStoreDoorClient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (longtitude !== undefined) {
                localVarQueryParameter['longtitude'] = longtitude;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 停止店铺设备计费
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopStoreDeviceStoreDevice: async (storeDeviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDeviceId' is not null or undefined
            assertParamExists('stopStoreDeviceStoreDevice', 'storeDeviceId', storeDeviceId)
            const localVarPath = `/storedevice/stopStoreDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeDeviceId !== undefined) {
                localVarQueryParameter['storeDeviceId'] = storeDeviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新店铺设备
         * @param {StoreDeviceAdminVo} storeDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreDeviceStoreDevice: async (storeDeviceAdminVo: StoreDeviceAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDeviceAdminVo' is not null or undefined
            assertParamExists('updateStoreDeviceStoreDevice', 'storeDeviceAdminVo', storeDeviceAdminVo)
            const localVarPath = `/storedevice/updateStoreDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeDeviceAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreDeviceApi - functional programming interface
 * @export
 */
export const StoreDeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreDeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加门店设备联动设备
         * @param {SystemGenerateObject34} systemGenerateObject34 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStoreDeviceCollDeviceStoreDevice(systemGenerateObject34: SystemGenerateObject34, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStoreDeviceCollDeviceStoreDevice(systemGenerateObject34, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加店铺设备
         * @param {StoreDeviceAdminVo} storeDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStoreDeviceStoreDevice(storeDeviceAdminVo: StoreDeviceAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStoreDeviceStoreDevice(storeDeviceAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除门店设备联动设备
         * @param {number} storeDeviceId 
         * @param {string} deviceIdHex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delStoreDeviceCollDeviceStoreDevice(storeDeviceId: number, deviceIdHex: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delStoreDeviceCollDeviceStoreDevice(storeDeviceId, deviceIdHex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除店铺设备
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delStoreDeviceStoreDevice(storeDeviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delStoreDeviceStoreDevice(storeDeviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取客户端店铺设备详情
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientStoreDeviceStoreDevice(storeDeviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientStoreDeviceStoreDevice(storeDeviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 当前店铺是否有门禁（客户端）
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasStoreDoorStoreDevice(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasStoreDoorStoreDevice(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取客户端门店设备列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClientStoreDeviceStoreDevice(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClientStoreDeviceStoreDevice(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取门店设备联动设备列表
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoreDeviceCollDeviceStoreDevice(storeDeviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoreDeviceCollDeviceStoreDevice(storeDeviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取店铺设备列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoreDeviceStoreDevice(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoreDeviceStoreDevice(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 客户端开门
         * @param {number} storeId 
         * @param {string} [longtitude] 
         * @param {string} [latitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openStoreDoorClientStoreDevice(storeId: number, longtitude?: string, latitude?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openStoreDoorClientStoreDevice(storeId, longtitude, latitude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 停止店铺设备计费
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopStoreDeviceStoreDevice(storeDeviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopStoreDeviceStoreDevice(storeDeviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新店铺设备
         * @param {StoreDeviceAdminVo} storeDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStoreDeviceStoreDevice(storeDeviceAdminVo: StoreDeviceAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStoreDeviceStoreDevice(storeDeviceAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreDeviceApi - factory interface
 * @export
 */
export const StoreDeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreDeviceApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加门店设备联动设备
         * @param {SystemGenerateObject34} systemGenerateObject34 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreDeviceCollDeviceStoreDevice(systemGenerateObject34: SystemGenerateObject34, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addStoreDeviceCollDeviceStoreDevice(systemGenerateObject34, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加店铺设备
         * @param {StoreDeviceAdminVo} storeDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreDeviceStoreDevice(storeDeviceAdminVo: StoreDeviceAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addStoreDeviceStoreDevice(storeDeviceAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除门店设备联动设备
         * @param {number} storeDeviceId 
         * @param {string} deviceIdHex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delStoreDeviceCollDeviceStoreDevice(storeDeviceId: number, deviceIdHex: string, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delStoreDeviceCollDeviceStoreDevice(storeDeviceId, deviceIdHex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除店铺设备
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delStoreDeviceStoreDevice(storeDeviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delStoreDeviceStoreDevice(storeDeviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取客户端店铺设备详情
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientStoreDeviceStoreDevice(storeDeviceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getClientStoreDeviceStoreDevice(storeDeviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 当前店铺是否有门禁（客户端）
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasStoreDoorStoreDevice(storeId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.hasStoreDoorStoreDevice(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取客户端门店设备列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientStoreDeviceStoreDevice(storeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.listClientStoreDeviceStoreDevice(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取门店设备联动设备列表
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreDeviceCollDeviceStoreDevice(storeDeviceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.listStoreDeviceCollDeviceStoreDevice(storeDeviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取店铺设备列表
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreDeviceStoreDevice(storeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.listStoreDeviceStoreDevice(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 客户端开门
         * @param {number} storeId 
         * @param {string} [longtitude] 
         * @param {string} [latitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openStoreDoorClientStoreDevice(storeId: number, longtitude?: string, latitude?: string, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.openStoreDoorClientStoreDevice(storeId, longtitude, latitude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 停止店铺设备计费
         * @param {number} storeDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopStoreDeviceStoreDevice(storeDeviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.stopStoreDeviceStoreDevice(storeDeviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新店铺设备
         * @param {StoreDeviceAdminVo} storeDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreDeviceStoreDevice(storeDeviceAdminVo: StoreDeviceAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateStoreDeviceStoreDevice(storeDeviceAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreDeviceApi - object-oriented interface
 * @export
 * @class StoreDeviceApi
 * @extends {BaseAPI}
 */
export class StoreDeviceApi extends BaseAPI {
    /**
     * 
     * @summary 添加门店设备联动设备
     * @param {SystemGenerateObject34} systemGenerateObject34 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDeviceApi
     */
    public addStoreDeviceCollDeviceStoreDevice(systemGenerateObject34: SystemGenerateObject34, options?: AxiosRequestConfig) {
        return StoreDeviceApiFp(this.configuration).addStoreDeviceCollDeviceStoreDevice(systemGenerateObject34, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加店铺设备
     * @param {StoreDeviceAdminVo} storeDeviceAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDeviceApi
     */
    public addStoreDeviceStoreDevice(storeDeviceAdminVo: StoreDeviceAdminVo, options?: AxiosRequestConfig) {
        return StoreDeviceApiFp(this.configuration).addStoreDeviceStoreDevice(storeDeviceAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除门店设备联动设备
     * @param {number} storeDeviceId 
     * @param {string} deviceIdHex 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDeviceApi
     */
    public delStoreDeviceCollDeviceStoreDevice(storeDeviceId: number, deviceIdHex: string, options?: AxiosRequestConfig) {
        return StoreDeviceApiFp(this.configuration).delStoreDeviceCollDeviceStoreDevice(storeDeviceId, deviceIdHex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除店铺设备
     * @param {number} storeDeviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDeviceApi
     */
    public delStoreDeviceStoreDevice(storeDeviceId: number, options?: AxiosRequestConfig) {
        return StoreDeviceApiFp(this.configuration).delStoreDeviceStoreDevice(storeDeviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取客户端店铺设备详情
     * @param {number} storeDeviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDeviceApi
     */
    public getClientStoreDeviceStoreDevice(storeDeviceId: number, options?: AxiosRequestConfig) {
        return StoreDeviceApiFp(this.configuration).getClientStoreDeviceStoreDevice(storeDeviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 当前店铺是否有门禁（客户端）
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDeviceApi
     */
    public hasStoreDoorStoreDevice(storeId: number, options?: AxiosRequestConfig) {
        return StoreDeviceApiFp(this.configuration).hasStoreDoorStoreDevice(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取客户端门店设备列表
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDeviceApi
     */
    public listClientStoreDeviceStoreDevice(storeId: number, options?: AxiosRequestConfig) {
        return StoreDeviceApiFp(this.configuration).listClientStoreDeviceStoreDevice(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取门店设备联动设备列表
     * @param {number} storeDeviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDeviceApi
     */
    public listStoreDeviceCollDeviceStoreDevice(storeDeviceId: number, options?: AxiosRequestConfig) {
        return StoreDeviceApiFp(this.configuration).listStoreDeviceCollDeviceStoreDevice(storeDeviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取店铺设备列表
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDeviceApi
     */
    public listStoreDeviceStoreDevice(storeId: number, options?: AxiosRequestConfig) {
        return StoreDeviceApiFp(this.configuration).listStoreDeviceStoreDevice(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 客户端开门
     * @param {number} storeId 
     * @param {string} [longtitude] 
     * @param {string} [latitude] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDeviceApi
     */
    public openStoreDoorClientStoreDevice(storeId: number, longtitude?: string, latitude?: string, options?: AxiosRequestConfig) {
        return StoreDeviceApiFp(this.configuration).openStoreDoorClientStoreDevice(storeId, longtitude, latitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 停止店铺设备计费
     * @param {number} storeDeviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDeviceApi
     */
    public stopStoreDeviceStoreDevice(storeDeviceId: number, options?: AxiosRequestConfig) {
        return StoreDeviceApiFp(this.configuration).stopStoreDeviceStoreDevice(storeDeviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新店铺设备
     * @param {StoreDeviceAdminVo} storeDeviceAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreDeviceApi
     */
    public updateStoreDeviceStoreDevice(storeDeviceAdminVo: StoreDeviceAdminVo, options?: AxiosRequestConfig) {
        return StoreDeviceApiFp(this.configuration).updateStoreDeviceStoreDevice(storeDeviceAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StoreManagerApi - axios parameter creator
 * @export
 */
export const StoreManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加店铺管理员
         * @param {StoreManagerAdminVo} storeManagerAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreManagerStoreManager: async (storeManagerAdminVo: StoreManagerAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeManagerAdminVo' is not null or undefined
            assertParamExists('addStoreManagerStoreManager', 'storeManagerAdminVo', storeManagerAdminVo)
            const localVarPath = `/storemanager/addStoreManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeManagerAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除店铺管理员
         * @param {number} phone 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreManagerStoreManager: async (phone: number, storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('deleteStoreManagerStoreManager', 'phone', phone)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('deleteStoreManagerStoreManager', 'storeId', storeId)
            const localVarPath = `/storemanager/deleteStoreManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取管理员的店铺列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagerStoreStoreManager: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storemanager/listManagerStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取店铺管理员列表
         * @param {SystemGenerateObject32} systemGenerateObject32 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreManagerStoreManager: async (systemGenerateObject32: SystemGenerateObject32, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject32' is not null or undefined
            assertParamExists('listStoreManagerStoreManager', 'systemGenerateObject32', systemGenerateObject32)
            const localVarPath = `/storemanager/listStoreManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject32, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取店铺管理员列表(分页)
         * @param {SystemGenerateObject33} systemGenerateObject33 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageStoreManagerStoreManager: async (systemGenerateObject33: SystemGenerateObject33, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject33' is not null or undefined
            assertParamExists('pageStoreManagerStoreManager', 'systemGenerateObject33', systemGenerateObject33)
            const localVarPath = `/storemanager/pageStoreManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject33, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreManagerApi - functional programming interface
 * @export
 */
export const StoreManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加店铺管理员
         * @param {StoreManagerAdminVo} storeManagerAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStoreManagerStoreManager(storeManagerAdminVo: StoreManagerAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStoreManagerStoreManager(storeManagerAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除店铺管理员
         * @param {number} phone 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStoreManagerStoreManager(phone: number, storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStoreManagerStoreManager(phone, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取管理员的店铺列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listManagerStoreStoreManager(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listManagerStoreStoreManager(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取店铺管理员列表
         * @param {SystemGenerateObject32} systemGenerateObject32 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoreManagerStoreManager(systemGenerateObject32: SystemGenerateObject32, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoreManagerStoreManager(systemGenerateObject32, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取店铺管理员列表(分页)
         * @param {SystemGenerateObject33} systemGenerateObject33 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageStoreManagerStoreManager(systemGenerateObject33: SystemGenerateObject33, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageStoreManagerStoreManager(systemGenerateObject33, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreManagerApi - factory interface
 * @export
 */
export const StoreManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreManagerApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加店铺管理员
         * @param {StoreManagerAdminVo} storeManagerAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreManagerStoreManager(storeManagerAdminVo: StoreManagerAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addStoreManagerStoreManager(storeManagerAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除店铺管理员
         * @param {number} phone 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreManagerStoreManager(phone: number, storeId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.deleteStoreManagerStoreManager(phone, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取管理员的店铺列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagerStoreStoreManager(options?: any): AxiosPromise<any> {
            return localVarFp.listManagerStoreStoreManager(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取店铺管理员列表
         * @param {SystemGenerateObject32} systemGenerateObject32 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreManagerStoreManager(systemGenerateObject32: SystemGenerateObject32, options?: any): AxiosPromise<any> {
            return localVarFp.listStoreManagerStoreManager(systemGenerateObject32, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取店铺管理员列表(分页)
         * @param {SystemGenerateObject33} systemGenerateObject33 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageStoreManagerStoreManager(systemGenerateObject33: SystemGenerateObject33, options?: any): AxiosPromise<any> {
            return localVarFp.pageStoreManagerStoreManager(systemGenerateObject33, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreManagerApi - object-oriented interface
 * @export
 * @class StoreManagerApi
 * @extends {BaseAPI}
 */
export class StoreManagerApi extends BaseAPI {
    /**
     * 
     * @summary 添加店铺管理员
     * @param {StoreManagerAdminVo} storeManagerAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreManagerApi
     */
    public addStoreManagerStoreManager(storeManagerAdminVo: StoreManagerAdminVo, options?: AxiosRequestConfig) {
        return StoreManagerApiFp(this.configuration).addStoreManagerStoreManager(storeManagerAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除店铺管理员
     * @param {number} phone 
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreManagerApi
     */
    public deleteStoreManagerStoreManager(phone: number, storeId: number, options?: AxiosRequestConfig) {
        return StoreManagerApiFp(this.configuration).deleteStoreManagerStoreManager(phone, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取管理员的店铺列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreManagerApi
     */
    public listManagerStoreStoreManager(options?: AxiosRequestConfig) {
        return StoreManagerApiFp(this.configuration).listManagerStoreStoreManager(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取店铺管理员列表
     * @param {SystemGenerateObject32} systemGenerateObject32 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreManagerApi
     */
    public listStoreManagerStoreManager(systemGenerateObject32: SystemGenerateObject32, options?: AxiosRequestConfig) {
        return StoreManagerApiFp(this.configuration).listStoreManagerStoreManager(systemGenerateObject32, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取店铺管理员列表(分页)
     * @param {SystemGenerateObject33} systemGenerateObject33 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreManagerApi
     */
    public pageStoreManagerStoreManager(systemGenerateObject33: SystemGenerateObject33, options?: AxiosRequestConfig) {
        return StoreManagerApiFp(this.configuration).pageStoreManagerStoreManager(systemGenerateObject33, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StorecomdeviceApi - axios parameter creator
 * @export
 */
export const StorecomdeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加普通设备
         * @param {StoreComDeviceAdminVo} storeComDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreComDevicestorecomdevice: async (storeComDeviceAdminVo: StoreComDeviceAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeComDeviceAdminVo' is not null or undefined
            assertParamExists('addStoreComDevicestorecomdevice', 'storeComDeviceAdminVo', storeComDeviceAdminVo)
            const localVarPath = `/storecomdevice/addStoreComDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeComDeviceAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除普通设备
         * @param {number} storeComDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delStoreComDevicestorecomdevice: async (storeComDeviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeComDeviceId' is not null or undefined
            assertParamExists('delStoreComDevicestorecomdevice', 'storeComDeviceId', storeComDeviceId)
            const localVarPath = `/storecomdevice/delStoreComDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeComDeviceId !== undefined) {
                localVarQueryParameter['storeComDeviceId'] = storeComDeviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取所有普通设备
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreComDevicestorecomdevice: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('listStoreComDevicestorecomdevice', 'storeId', storeId)
            const localVarPath = `/storecomdevice/listStoreComDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新普通设备
         * @param {StoreComDeviceAdminVo} storeComDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreComDevicestorecomdevice: async (storeComDeviceAdminVo: StoreComDeviceAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeComDeviceAdminVo' is not null or undefined
            assertParamExists('updateStoreComDevicestorecomdevice', 'storeComDeviceAdminVo', storeComDeviceAdminVo)
            const localVarPath = `/storecomdevice/updateStoreComDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeComDeviceAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorecomdeviceApi - functional programming interface
 * @export
 */
export const StorecomdeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorecomdeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加普通设备
         * @param {StoreComDeviceAdminVo} storeComDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStoreComDevicestorecomdevice(storeComDeviceAdminVo: StoreComDeviceAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStoreComDevicestorecomdevice(storeComDeviceAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除普通设备
         * @param {number} storeComDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delStoreComDevicestorecomdevice(storeComDeviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delStoreComDevicestorecomdevice(storeComDeviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取所有普通设备
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoreComDevicestorecomdevice(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoreComDevicestorecomdevice(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新普通设备
         * @param {StoreComDeviceAdminVo} storeComDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStoreComDevicestorecomdevice(storeComDeviceAdminVo: StoreComDeviceAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStoreComDevicestorecomdevice(storeComDeviceAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorecomdeviceApi - factory interface
 * @export
 */
export const StorecomdeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorecomdeviceApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加普通设备
         * @param {StoreComDeviceAdminVo} storeComDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreComDevicestorecomdevice(storeComDeviceAdminVo: StoreComDeviceAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addStoreComDevicestorecomdevice(storeComDeviceAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除普通设备
         * @param {number} storeComDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delStoreComDevicestorecomdevice(storeComDeviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delStoreComDevicestorecomdevice(storeComDeviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取所有普通设备
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreComDevicestorecomdevice(storeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.listStoreComDevicestorecomdevice(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新普通设备
         * @param {StoreComDeviceAdminVo} storeComDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreComDevicestorecomdevice(storeComDeviceAdminVo: StoreComDeviceAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateStoreComDevicestorecomdevice(storeComDeviceAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorecomdeviceApi - object-oriented interface
 * @export
 * @class StorecomdeviceApi
 * @extends {BaseAPI}
 */
export class StorecomdeviceApi extends BaseAPI {
    /**
     * 
     * @summary 添加普通设备
     * @param {StoreComDeviceAdminVo} storeComDeviceAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorecomdeviceApi
     */
    public addStoreComDevicestorecomdevice(storeComDeviceAdminVo: StoreComDeviceAdminVo, options?: AxiosRequestConfig) {
        return StorecomdeviceApiFp(this.configuration).addStoreComDevicestorecomdevice(storeComDeviceAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除普通设备
     * @param {number} storeComDeviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorecomdeviceApi
     */
    public delStoreComDevicestorecomdevice(storeComDeviceId: number, options?: AxiosRequestConfig) {
        return StorecomdeviceApiFp(this.configuration).delStoreComDevicestorecomdevice(storeComDeviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取所有普通设备
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorecomdeviceApi
     */
    public listStoreComDevicestorecomdevice(storeId: number, options?: AxiosRequestConfig) {
        return StorecomdeviceApiFp(this.configuration).listStoreComDevicestorecomdevice(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新普通设备
     * @param {StoreComDeviceAdminVo} storeComDeviceAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorecomdeviceApi
     */
    public updateStoreComDevicestorecomdevice(storeComDeviceAdminVo: StoreComDeviceAdminVo, options?: AxiosRequestConfig) {
        return StorecomdeviceApiFp(this.configuration).updateStoreComDevicestorecomdevice(storeComDeviceAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StoredoordeviceApi - axios parameter creator
 * @export
 */
export const StoredoordeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加门禁设备
         * @param {StoreDoorDeviceAdminVo} storeDoorDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreDoorDevicestoredoordevice: async (storeDoorDeviceAdminVo: StoreDoorDeviceAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDoorDeviceAdminVo' is not null or undefined
            assertParamExists('addStoreDoorDevicestoredoordevice', 'storeDoorDeviceAdminVo', storeDoorDeviceAdminVo)
            const localVarPath = `/storedoordevice/addStoreDoorDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeDoorDeviceAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除门禁设备
         * @param {number} storeDoorDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delStoreDoorDevicestoredoordevice: async (storeDoorDeviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDoorDeviceId' is not null or undefined
            assertParamExists('delStoreDoorDevicestoredoordevice', 'storeDoorDeviceId', storeDoorDeviceId)
            const localVarPath = `/storedoordevice/delStoreDoorDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeDoorDeviceId !== undefined) {
                localVarQueryParameter['storeDoorDeviceId'] = storeDoorDeviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取所有门禁设备
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreDoorDevicestoredoordevice: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('listStoreDoorDevicestoredoordevice', 'storeId', storeId)
            const localVarPath = `/storedoordevice/listStoreDoorDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新门禁设备
         * @param {StoreDoorDeviceAdminVo} storeDoorDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreDoorDevicestoredoordevice: async (storeDoorDeviceAdminVo: StoreDoorDeviceAdminVo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDoorDeviceAdminVo' is not null or undefined
            assertParamExists('updateStoreDoorDevicestoredoordevice', 'storeDoorDeviceAdminVo', storeDoorDeviceAdminVo)
            const localVarPath = `/storedoordevice/updateStoreDoorDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeDoorDeviceAdminVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoredoordeviceApi - functional programming interface
 * @export
 */
export const StoredoordeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoredoordeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加门禁设备
         * @param {StoreDoorDeviceAdminVo} storeDoorDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStoreDoorDevicestoredoordevice(storeDoorDeviceAdminVo: StoreDoorDeviceAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStoreDoorDevicestoredoordevice(storeDoorDeviceAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除门禁设备
         * @param {number} storeDoorDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delStoreDoorDevicestoredoordevice(storeDoorDeviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delStoreDoorDevicestoredoordevice(storeDoorDeviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取所有门禁设备
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoreDoorDevicestoredoordevice(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoreDoorDevicestoredoordevice(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新门禁设备
         * @param {StoreDoorDeviceAdminVo} storeDoorDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStoreDoorDevicestoredoordevice(storeDoorDeviceAdminVo: StoreDoorDeviceAdminVo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStoreDoorDevicestoredoordevice(storeDoorDeviceAdminVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoredoordeviceApi - factory interface
 * @export
 */
export const StoredoordeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoredoordeviceApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加门禁设备
         * @param {StoreDoorDeviceAdminVo} storeDoorDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreDoorDevicestoredoordevice(storeDoorDeviceAdminVo: StoreDoorDeviceAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.addStoreDoorDevicestoredoordevice(storeDoorDeviceAdminVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除门禁设备
         * @param {number} storeDoorDeviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delStoreDoorDevicestoredoordevice(storeDoorDeviceId: number, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.delStoreDoorDevicestoredoordevice(storeDoorDeviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取所有门禁设备
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoreDoorDevicestoredoordevice(storeId: number, options?: any): AxiosPromise<any> {
            return localVarFp.listStoreDoorDevicestoredoordevice(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新门禁设备
         * @param {StoreDoorDeviceAdminVo} storeDoorDeviceAdminVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreDoorDevicestoredoordevice(storeDoorDeviceAdminVo: StoreDoorDeviceAdminVo, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.updateStoreDoorDevicestoredoordevice(storeDoorDeviceAdminVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoredoordeviceApi - object-oriented interface
 * @export
 * @class StoredoordeviceApi
 * @extends {BaseAPI}
 */
export class StoredoordeviceApi extends BaseAPI {
    /**
     * 
     * @summary 添加门禁设备
     * @param {StoreDoorDeviceAdminVo} storeDoorDeviceAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoredoordeviceApi
     */
    public addStoreDoorDevicestoredoordevice(storeDoorDeviceAdminVo: StoreDoorDeviceAdminVo, options?: AxiosRequestConfig) {
        return StoredoordeviceApiFp(this.configuration).addStoreDoorDevicestoredoordevice(storeDoorDeviceAdminVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除门禁设备
     * @param {number} storeDoorDeviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoredoordeviceApi
     */
    public delStoreDoorDevicestoredoordevice(storeDoorDeviceId: number, options?: AxiosRequestConfig) {
        return StoredoordeviceApiFp(this.configuration).delStoreDoorDevicestoredoordevice(storeDoorDeviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取所有门禁设备
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoredoordeviceApi
     */
    public listStoreDoorDevicestoredoordevice(storeId: number, options?: AxiosRequestConfig) {
        return StoredoordeviceApiFp(this.configuration).listStoreDoorDevicestoredoordevice(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新门禁设备
     * @param {StoreDoorDeviceAdminVo} storeDoorDeviceAdminVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoredoordeviceApi
     */
    public updateStoreDoorDevicestoredoordevice(storeDoorDeviceAdminVo: StoreDoorDeviceAdminVo, options?: AxiosRequestConfig) {
        return StoredoordeviceApiFp(this.configuration).updateStoreDoorDevicestoredoordevice(storeDoorDeviceAdminVo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 获取swagger.json
         * @summary swagger接口文档
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _64150000000000000000system: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/swagger.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 获取swagger.json
         * @summary swagger接口文档
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _64150000000000000000system(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._64150000000000000000system(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 获取swagger.json
         * @summary swagger接口文档
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _64150000000000000000system(options?: any): AxiosPromise<any> {
            return localVarFp._64150000000000000000system(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 获取swagger.json
     * @summary swagger接口文档
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public _64150000000000000000system(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration)._64150000000000000000system(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 你好
         * @summary 你好
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hellotest: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test/hello`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 你好
         * @summary 你好
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hellotest(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hellotest(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 你好
         * @summary 你好
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hellotest(name?: string, options?: any): AxiosPromise<any> {
            return localVarFp.hellotest(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 你好
     * @summary 你好
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public hellotest(name?: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).hellotest(name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 上传图片
         * @summary 上传图片
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUpload: async (files: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            assertParamExists('imageUpload', 'files', files)
            const localVarPath = `/upload/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 上传图片
         * @summary 上传图片
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageUpload(files: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageUpload(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * 上传图片
         * @summary 上传图片
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUpload(files: Array<File>, options?: any): AxiosPromise<any> {
            return localVarFp.imageUpload(files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 上传图片
     * @summary 上传图片
     * @param {Array<File>} files 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public imageUpload(files: Array<File>, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).imageUpload(files, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserweappApi - axios parameter creator
 * @export
 */
export const UserweappApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 微信小程序用户登录
         * @param {SystemGenerateObject56} systemGenerateObject56 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginuserweapp: async (systemGenerateObject56: SystemGenerateObject56, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject56' is not null or undefined
            assertParamExists('loginuserweapp', 'systemGenerateObject56', systemGenerateObject56)
            const localVarPath = `/userweapp/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject56, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 微信小程序用户注册
         * @param {SystemGenerateObject57} systemGenerateObject57 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registeruserweapp: async (systemGenerateObject57: SystemGenerateObject57, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject57' is not null or undefined
            assertParamExists('registeruserweapp', 'systemGenerateObject57', systemGenerateObject57)
            const localVarPath = `/userweapp/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject57, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 微信小程序验证token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        veryfyTokenuserweapp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/userweapp/veryfyToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserweappApi - functional programming interface
 * @export
 */
export const UserweappApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserweappApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 微信小程序用户登录
         * @param {SystemGenerateObject56} systemGenerateObject56 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginuserweapp(systemGenerateObject56: SystemGenerateObject56, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginuserweapp(systemGenerateObject56, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 微信小程序用户注册
         * @param {SystemGenerateObject57} systemGenerateObject57 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registeruserweapp(systemGenerateObject57: SystemGenerateObject57, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registeruserweapp(systemGenerateObject57, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 微信小程序验证token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async veryfyTokenuserweapp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.veryfyTokenuserweapp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserweappApi - factory interface
 * @export
 */
export const UserweappApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserweappApiFp(configuration)
    return {
        /**
         * 
         * @summary 微信小程序用户登录
         * @param {SystemGenerateObject56} systemGenerateObject56 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginuserweapp(systemGenerateObject56: SystemGenerateObject56, options?: any): AxiosPromise<any> {
            return localVarFp.loginuserweapp(systemGenerateObject56, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 微信小程序用户注册
         * @param {SystemGenerateObject57} systemGenerateObject57 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registeruserweapp(systemGenerateObject57: SystemGenerateObject57, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.registeruserweapp(systemGenerateObject57, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 微信小程序验证token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        veryfyTokenuserweapp(options?: any): AxiosPromise<ResultVo> {
            return localVarFp.veryfyTokenuserweapp(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserweappApi - object-oriented interface
 * @export
 * @class UserweappApi
 * @extends {BaseAPI}
 */
export class UserweappApi extends BaseAPI {
    /**
     * 
     * @summary 微信小程序用户登录
     * @param {SystemGenerateObject56} systemGenerateObject56 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserweappApi
     */
    public loginuserweapp(systemGenerateObject56: SystemGenerateObject56, options?: AxiosRequestConfig) {
        return UserweappApiFp(this.configuration).loginuserweapp(systemGenerateObject56, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 微信小程序用户注册
     * @param {SystemGenerateObject57} systemGenerateObject57 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserweappApi
     */
    public registeruserweapp(systemGenerateObject57: SystemGenerateObject57, options?: AxiosRequestConfig) {
        return UserweappApiFp(this.configuration).registeruserweapp(systemGenerateObject57, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 微信小程序验证token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserweappApi
     */
    public veryfyTokenuserweapp(options?: AxiosRequestConfig) {
        return UserweappApiFp(this.configuration).veryfyTokenuserweapp(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserwxApi - axios parameter creator
 * @export
 */
export const UserwxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 公众号绑定用户
         * @param {SystemGenerateObject61} systemGenerateObject61 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bindUseruserwx: async (systemGenerateObject61: SystemGenerateObject61, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject61' is not null or undefined
            assertParamExists('bindUseruserwx', 'systemGenerateObject61', systemGenerateObject61)
            const localVarPath = `/userwx/bindUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject61, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 公众号发送验证码
         * @param {SystemGenerateObject60} systemGenerateObject60 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCodeuserwx: async (systemGenerateObject60: SystemGenerateObject60, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemGenerateObject60' is not null or undefined
            assertParamExists('sendCodeuserwx', 'systemGenerateObject60', systemGenerateObject60)
            const localVarPath = `/userwx/sendCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemGenerateObject60, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserwxApi - functional programming interface
 * @export
 */
export const UserwxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserwxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 公众号绑定用户
         * @param {SystemGenerateObject61} systemGenerateObject61 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bindUseruserwx(systemGenerateObject61: SystemGenerateObject61, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bindUseruserwx(systemGenerateObject61, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 公众号发送验证码
         * @param {SystemGenerateObject60} systemGenerateObject60 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendCodeuserwx(systemGenerateObject60: SystemGenerateObject60, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendCodeuserwx(systemGenerateObject60, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserwxApi - factory interface
 * @export
 */
export const UserwxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserwxApiFp(configuration)
    return {
        /**
         * 
         * @summary 公众号绑定用户
         * @param {SystemGenerateObject61} systemGenerateObject61 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bindUseruserwx(systemGenerateObject61: SystemGenerateObject61, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.bindUseruserwx(systemGenerateObject61, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 公众号发送验证码
         * @param {SystemGenerateObject60} systemGenerateObject60 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCodeuserwx(systemGenerateObject60: SystemGenerateObject60, options?: any): AxiosPromise<ResultVo> {
            return localVarFp.sendCodeuserwx(systemGenerateObject60, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserwxApi - object-oriented interface
 * @export
 * @class UserwxApi
 * @extends {BaseAPI}
 */
export class UserwxApi extends BaseAPI {
    /**
     * 
     * @summary 公众号绑定用户
     * @param {SystemGenerateObject61} systemGenerateObject61 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserwxApi
     */
    public bindUseruserwx(systemGenerateObject61: SystemGenerateObject61, options?: AxiosRequestConfig) {
        return UserwxApiFp(this.configuration).bindUseruserwx(systemGenerateObject61, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 公众号发送验证码
     * @param {SystemGenerateObject60} systemGenerateObject60 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserwxApi
     */
    public sendCodeuserwx(systemGenerateObject60: SystemGenerateObject60, options?: AxiosRequestConfig) {
        return UserwxApiFp(this.configuration).sendCodeuserwx(systemGenerateObject60, options).then((request) => request(this.axios, this.basePath));
    }
}



